<template>
  <div id="VRInfo">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>{{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <el-table
        highlight-current-row
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%; height: 100%; overflow: auto"
        @selection-change="handleSelectionChange"
        @row-click="rowclick"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column sortable prop="name" label="VR名称" width="200">
        </el-table-column>
        <el-table-column sortable prop="url" label="VR服务地址">
        </el-table-column>
        <el-table-column sortable prop="vrUrl" label="VR发布地址">
        </el-table-column>
        <el-table-column width="300">
          <template
            slot="header"
            slot-scope="/* eslint-disable vue/no-unused-vars */ scope"
          >
            <div class="operateArea">
              <div class="btnArea">
                <el-tooltip
                  v-for="(item, index) in tableMenuBtn"
                  :key="index"
                  class="item"
                  effect="dark"
                  :content="item.label"
                  placement="top"
                >
                  <i
                    v-has="item"
                    @click="tableMenuBtnClick(item.method)"
                    :class="item.class"
                  ></i>
                </el-tooltip>
              </div>
              <el-input
                v-model="search"
                size="mini"
                placeholder="VR名称搜索"
                @keyup.enter.native="getTableData"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <div class="tableBtnArea">
              <el-button
                v-for="(item, index) in rowBtn"
                :key="index"
                v-has="item"
                size="mini"
                @click="handleEdit(item.label, scope.row)"
                >{{ item.label }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="btnArea">
      <div
        class="itemBtn"
        v-for="(item, index) in btnData"
        @click="btnClick(item, index)"
        :key="index"
        v-has="item"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "新建",
          page: "VRInfo",
          class: "el-icon-plus itemBtn",
          method: "add",
        },
        {
          type: "authorityData",
          label: "删除",
          page: "VRInfo",
          class: "el-icon-delete itemBtn",
          method: "delete",
        },
      ],
      //每一行的按钮
      rowBtn: [
        {
          type: "authorityData",
          label: "预览",
          page: "VRInfo",
        },
      ],
      //分页数据
      pageSize: 10,
      total: 10,
      currentPage: 1,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建VR资源",
        listData: [
          {
            type: "input",
            name: "vr名称",
            value: "",
            isNotNull: true,
          },
          {
            type: "textarea",
            name: "vr地址",
            value: "",
            isNotNull: true,
          },
        ],
      },
      search: "",
      tableData: [
        {
          id: "1",
          name: "vr名称",
          url: "vr链接",
        },
      ],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "input",
          name: "vr名称",
          value: "",
          isNotNull: true,
        },
        {
          type: "textarea",
          name: "vr地址",
          value: "",
          isNotNull: true,
        },
      ],
      btnData: [
        {
          type: "authorityData",
          page: "VRInfo",
          label: "保存",
        },
        {
          type: "authorityData",
          page: "VRInfo",
          label: "预览",
        },
        {
          type: "authorityData",
          page: "VRInfo",
          label: "删除",
        },
      ],
      multipleSelection: [],
    };
  },
  mounted() {
    // 初始化列表
    this.getTableData();
  },
  methods: {
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/vr/getVRList",
        {
          current: this.currentPage,
          size: this.pageSize,
          search: this.search,
        },
        (data) => {
          this.tableData = data.result.records;
          this.total = data.result.total;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 删除并刷新
    deleteKeyWord(id) {
      this.$request(
        "post",
        "/picResource/delete",
        {
          userId: "1",
          idList: this.multipleSelection,
        },
        (data) => {
          this.currentRow = {};
          this.getTableData();
        }
      );
    },
    handleEdit(type, row) {
      switch (type) {
        case "预览":
          window.open(row.vrUrl, "_blank");
          break;

        default:
          break;
      }
    },
    //底部按钮点击事件
    btnClick(item, index) {
      switch (item.label) {
        case "保存":
          if (this.listData[0].value == "" || this.listData[1].value == "") {
            this.$message.error("未选中任何数据!");
            return;
          }
          if (this.listData[0].value.length > 50) {
            this.$message.error("Vr名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.listData[1].value.length > 200) {
            this.$message.error("Vr地址过长,请重新重新填写(最大长度200)!");
            return;
          }
          this.$request(
            "post",
            "/vr/updateVR",
            {
              userId: "1",
              id: this.currentRow.id,
              name: this.listData[0].value,
              address: this.listData[1].value,
            },
            (data) => {
              this.$message.success("保存成功");
              this.getTableData();
            }
          );
          break;
        case "删除":
          if (this.listData[0].value == "" || this.listData[1].value == "") {
            this.$message.error("未选中任何数据!");
            return;
          }
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/vr/deleteVR",
                {
                  userId: "1",
                  idList: [
                    {
                      id: this.currentRow.id,
                    },
                  ],
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "预览":
          if (this.listData[0].value == "" || this.listData[1].value == "") {
            this.$message.error("未选中任何数据!");
            return;
          }
          window.open(this.currentRow.url, "_blank");
          break;
        default:
          break;
      }
    },
    //行点击事件
    rowclick(row, colum, event) {
      this.currentRow = row;
      this.listData[0].value = row.name;
      this.listData[1].value = row.url;
    },
    //表格顶部右上侧按钮点击事件
    tableMenuBtnClick(type) {
      switch (type) {
        //批量删除
        case "delete":
          if (this.multipleSelection.length == 0) {
            this.$message({
              type: "error",
              message: "未选择任何信息!",
            });
            return;
          }
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/vr/deleteVR",
                {
                  userId: "1",
                  idList: this.multipleSelection,
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "add":
          this.dialogVisible = true;
          break;

        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建VR资源":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("Vr名称不可为空!");
            return;
          }
          if (this.currentDialogData.listData[1].value == "") {
            this.$message.error("Vr地址不可为空!");
            return;
          }

          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("Vr名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[1].value.length > 200) {
            this.$message.error("Vr地址过长,请重新重新填写(最大长度200)!");
            return;
          }
          this.$request(
            "post",
            "/vr/addVR",
            {
              userId: "1",
              name: this.currentDialogData.listData[0].value,
              url: this.currentDialogData.listData[1].value,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建VR资源成功!");
              this.getTableData();
            }
          );
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    filterType(value, row) {
      return row.type === value;
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      //console.log(`当前页: ${val}`);
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = value;
      this.getTableData();
      //console.log(`每页 ${val} 条`);
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["VRInfo"],
          relationId: null,
          type: 0,
        },
        (data) => {
          if (
            data.result.VRInfo.checkList == null ||
            data.result.VRInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("VRInfo", JSON.stringify(data.result));
        }
      );
    },
  },
  beforeCreate() {
    localStorage.removeItem("VRInfo");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   VRInfo: {
    //     name: "站点回收站",
    //     checkList: ["新建", "保存", "预览", "删除"],
    //   },
    // };
    // localStorage.setItem("VRInfo", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#VRInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .el-table {
      position: absolute;
      min-width: 1000px;
      overflow: auto;
      .tableBtnArea {
        display: flex;
      }
      .titleArea {
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        .newArea {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01cb94;
          position: absolute;
          float: left;
          left: -10px;
          top: 5px;
        }
        .picArea {
          width: 32px;
          height: 32px;
        }
        .videoArea {
          width: 32px;
          height: 32px;
        }
      }
      .operateArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > .btnArea {
          display: flex;
        }
        .itemBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.5s;
          margin: 0 3px;
          &:hover {
            color: #01cb94;
          }
        }
        .el-input {
          max-width: 200px;
        }
      }
      .el-table__cell {
        padding: 3px 0;
      }
    }
  }
  > .block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>