import Vue from 'vue'

export function initDialogData(dialogData) {
    for (let index = 0; index < dialogData.length; index++) {
        const element = dialogData[index];
        element.value = ""
    }
    return dialogData
}

export function exitSys(this_) {
    // 生产环境退出
    this_.$request("post", "/user/loginOut", { userId: "1" }, (data) => {
        window.open(data.result.loginOutUrl, "_self");
    });

    // 测试环境退出
    // localStorage.removeItem("userId");
    // window.open(this_.$baseurl + "/user/loginOut", "_self");
}