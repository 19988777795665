<template>
  <div id="test1">
    <div class="div1">
        <div class="iconArea">icon</div>
        <div class="div3">
            <div class="div4"></div>
        </div>
        <div class="div5"></div>
    </div>
    <div class="div2"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
#test1 {
  width: 800px;
  height: 500px;
  display: flex;
  margin: 100px;
//   overflow: auto;
  .div1 {
    flex: 1;
    height: 100%;
    background-color: aqua;
    display: flex;
    // overflow: auto;
    position: relative;
    .iconArea{
        // display: flex;
        float: left;
        position: absolute;
        transform: translateX(-100%);
        z-index: 2;
    }
    .div3{
        flex: 1;
        height: 100%;
        background-color: rgb(255, 137, 137);
        overflow: auto;
        .div4{
            // 宽度
            // div4>div3宽度,则div3宽度变为div4宽度
            width: 200px;
            height: 100%;
        }
    }
     .div5{
        flex: 1;
        height: 100%;
        background-color: rgb(149, 137, 255);
        overflow: auto;
    }
  }
  .div2 {
    flex: 3;
    height: 100%;
    background-color: rgb(127, 178, 255);
  }
}
</style>