<template>
  <div id="docInfoTrue" @click="preview">
    <!-- 日志 -->
    <el-drawer title="版本/操作记录" :visible.sync="drawerFlag" direction="rtl" size="60%">
      <div class="versionArea">
        <div>最近历史版本</div>
        <div>
          <el-table tooltip-effect="dark" border highlight-current-row max-height="450" :data="versionData">
            <el-table-column property="time" sortable label="时间" width="170"></el-table-column>
            <el-table-column property="name" label="版本保存者" show-overflow-tooltip width="200"></el-table-column>
            <el-table-column property="address" label="操作">
              <template slot-scope="scope">
                <div class="tableBtnArea">
                  <el-button v-has="item" v-for="(item, index) in drawTableRowBtn" :key="index" size="mini"
                    @click="versionTableRowClick(item.label, scope.row)">{{ item.label }}</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="logArea">
        <div>操作记录</div>
        <div>
          <el-table tooltip-effect="dark" border max-height="300" highlight-current-row :data="operateData">
            <el-table-column property="time" label="时间" sortable width="170"></el-table-column>
            <el-table-column property="name" label="操作者" width="200" show-overflow-tooltip></el-table-column>
            <el-table-column property="operation" label="操作内容" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <!-- 发布专用弹窗 -->
    <el-dialog :title="currentDialogData2.title" :visible.sync="dialogVisible2" width="60%" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="itemOption" v-for="(item, index) in currentDialogData2.listData" :key="index">
        <div class="leftArea" v-if="index < 1">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :data="uploadData"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" :placeholder="'请输入' + item.name"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name" filterable>
            <el-option v-for="item2 in item.valueList" :key="item2.id" :label="item2.name" :value="item2.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption" v-if="currentDialogData2.listData[0].value == '2'">
        <div class="leftArea">{{ currentDialogData2.listData[1].name }}:</div>
        <div class="rightArea">
          <el-date-picker v-model="currentDialogData2.listData[1].value" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease(false)">确 定</el-button>
        <el-button @click="dialogVisible2 = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 复制/移动/引用 -->
    <el-dialog :title="copyOrCut.title" :visible.sync="copyOrCut.dialogVisible" width="60%" :before-close="handleClose3"
      :close-on-click-modal="false">
      <div class="itemOption">
        <div class="leftArea">选择站点:</div>
        <div class="rightArea">
          <el-select v-model="copyOrCut.site" :placeholder="'请选择'" filterable @change="getColumnTree">
            <el-option v-for="item2 in copyOrCut.siteList" :key="item2.id" :label="item2.name" :value="item2.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption">
        <div class="leftArea">选择栏目:</div>
        <div class="rightArea">
          <el-cascader v-model="copyOrCut.column" placeholder="请选择" :options="copyOrCut.columnList" clearable
            :show-all-levels="false" :props="{
              checkStrictly: false,
              emitPath: false,
              multiple: copyOrCut.title == '文档复制',
              value: 'id',
              label: 'name',
            }" filterable></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureCopyOrCut()">确 定</el-button>
        <el-button @click="handleClose3">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 置顶 -->
    <el-dialog :title="sitckDialogData.title" :visible.sync="sitckDialogData.flag" width="40%"
      :close-on-click-modal="false">
      <div class="itemOption">
        <div class="leftArea">置顶方式:</div>
        <div class="rightArea">
          <el-radio-group v-model="sitckDialogData.sitckFlag">
            <el-radio :label="0">普通置顶</el-radio>
            <el-radio :label="1">限时置顶</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="itemOption" v-if="sitckDialogData.sitckFlag == 1">
        <div class="leftArea">取消置顶时间:</div>
        <div class="rightArea">
          <el-date-picker v-model="sitckDialogData.time" type="datetime" placeholder="选择日期时间" clearable
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureStick()">确 定</el-button>
        <el-button @click="sitckDialogData.flag = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 定时撤稿 -->
    <el-dialog :title="revokeTimingkDialogData.title" :visible.sync="revokeTimingkDialogData.flag" width="40%"
      :close-on-click-modal="false">
      <div class="itemOption">
        <div class="leftArea">撤稿时间:</div>
        <div class="rightArea">
          <el-date-picker v-model="revokeTimingkDialogData.time" type="datetime" placeholder="选择日期时间" clearable
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="revokeTiming()">确 定</el-button>
        <el-button @click="revokeTimingkDialogData.flag = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 调整顺序 -->
    <el-dialog :title="sequenceDialogData.title" :visible.sync="sequenceDialogData.flag" width="40%"
      :close-on-click-modal="false">
      <div class="itemOption">
        <div class="leftArea">将当前文档置于本栏目:</div>
        <div class="rightArea">
          <el-radio-group v-model="sequenceDialogData.sequenceFlag">
            <el-radio :label="2">最前面</el-radio>
            <el-radio :label="3">最后面</el-radio>
            <el-radio :label="0">指定位置</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="itemOption" v-if="sequenceDialogData.sequenceFlag == 0">
        <div class="leftArea"></div>
        <div class="rightArea">
          <el-input v-model="sequenceDialogData.index" placeholder="请输入指定位置" clearable></el-input>
        </div>
      </div>
      <div class="itemOption" v-if="sequenceDialogData.sequenceFlag == 0" style="margin-top: 10px">
        <div class="leftArea"></div>
        <div class="rightArea">
          <span class="zhiding" @click="getDocListBySort">指定文档之前</span>
        </div>
      </div>
      <div class="itemOption" style="margin-top: 20px">
        <div class="leftArea"></div>
        <div class="rightArea">
          当前文档位置:<span style="margin: 0 5px; color: #2a9bf8">{{
            sequenceDialogData.oldIndex
          }}</span>
          本栏目总条数:<span style="margin: 0 5px; color: #2a9bf8">{{
            sequenceDialogData.total
          }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureSequence(sequenceDialogData.sequenceFlag)">确 定</el-button>
        <el-button @click="sequenceDialogData.flag = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 调整顺序 -- 选择指定文档 -->
    <el-dialog :title="sequenceDocListDialogData.title" :visible.sync="sequenceDocListDialogData.flag" width="40%"
      :close-on-click-modal="false">
      <div class="itemOption">
        <div class="rightArea">
          <el-table :data="sequenceDocListDialogDataTableData" style="width: 100%" @row-click="clickRow" height="430px">
            <el-table-column prop="date" label="序号" width="55">
              <template slot-scope="scope">
                {{
                  sequenceDocListDialogData.pageData.pageSize *
                  (sequenceDocListDialogData.pageData.currentPage - 1) +
                  scope.$index +
                  1
                }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="文档名称"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="itemOption" style="margin-top: 10px">
        <div class="rightArea">
          <el-pagination @size-change="handleSizeChangeDialog" @current-change="handleCurrentChangeDialog"
            :current-page.sync="sequenceDocListDialogData.pageData.currentPage" :page-sizes="[8, 16, 48, 64]"
            :page-size="sequenceDocListDialogData.pageData.pageSize" layout="total,sizes,prev, pager, next, jumper"
            :total="sequenceDocListDialogData.pageData.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 文档追踪 -->
    <el-dialog :title="wendangzhuizongDialogData.title" :visible.sync="wendangzhuizongDialogData.flag" width="40%"
      :close-on-click-modal="false">
      <div class="wdzz">
        <div class="item">
          <div class="title">
            <div class="sp"></div>
            <div class="te">文档复制情况</div>
          </div>
          <div class="ly">
            <div class="tit">来源:</div>
            <div class="va" v-if="copyDetails.source &&
              copyDetails.source.time &&
              copyDetails.source.time != ''
              ">
              <div class="vv">站点: {{ copyDetails.source.site }}</div>
              <div class="vv">栏目: {{ copyDetails.source.column }}</div>
              <div class="vv ti">复制时间: {{ copyDetails.source.time }}</div>
            </div>
            <div class="va" v-else>无</div>
          </div>
          <div class="ly">
            <div class="tit">去向:</div>
            <div class="va">
              <el-table :data="copyDetails.targetList" style="width: 100%" height="430px" border>
                <el-table-column prop="site" label="站点"> </el-table-column>
                <el-table-column prop="column" label="栏目"> </el-table-column>
                <el-table-column prop="time" label="复制时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="sp"></div>
            <div class="te">文档引用情况</div>
          </div>
          <div class="ly">
            <div class="tit">来源:</div>
            <div class="va" v-if="quoteDetails.source &&
              quoteDetails.source.time &&
              quoteDetails.source.time != ''
              ">
              <div class="vv bor">站点: {{ quoteDetails.source.site }}</div>
              <div class="vv">栏目: {{ quoteDetails.source.column }}</div>
              <div class="vv ti">引用时间: {{ quoteDetails.source.time }}</div>
            </div>
            <div class="va" v-else>无</div>
          </div>
          <div class="ly">
            <div class="tit">去向:</div>
            <div class="va">
              <el-table :data="quoteDetails.targetList" style="width: 100%" height="430px" border>
                <el-table-column prop="site" label="站点"> </el-table-column>
                <el-table-column prop="column" label="栏目"> </el-table-column>
                <el-table-column prop="time" label="引用时间">
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="sp"></div>
            <div class="te">文档移动情况</div>
          </div>
          <div class="ly">
            <div class="tit">来源:</div>
            <div class="va" v-if="moveDetails.source &&
              moveDetails.source.time &&
              moveDetails.source.time != ''
              ">
              <div class="vv bor">站点: {{ moveDetails.source.site }}</div>
              <div class="vv">栏目: {{ moveDetails.source.column }}</div>
              <div class="vv ti">移动时间: {{ moveDetails.source.time }}</div>
            </div>
            <div class="va" v-else>无</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="content">
      <div class="contentArea" ref="tebleArea" @contextmenu="nullContextClick">
        <el-table highlight-current-row ref="multipleTable" @row-click="preview" @row-contextmenu="tableContextmenu"
          tooltip-effect="dark" v-loading="loading" border :data="tableData"
          style="width: 100%; height: 100%; overflow: auto" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="40"> </el-table-column>
          <el-table-column type="index" width="40"><template
              slot-scope="scope">{{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="预览" width="55" align="center">
            <template slot-scope="scope"><span style="cursor: pointer"><i class="el-icon-search"
                  @click="showDoc(scope.row.id)"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="title" label="文档标题" show-overflow-tooltip class-name="firstColumn">
            <template slot="header" slot-scope="/* eslint-disable vue/no-unused-vars */ scope">
              <div class="operateArea">
                <span>文档标题</span>
                <div class="input">
                  <el-input v-model="search" size="mini" @input="change($event)" placeholder="标题搜索"
                    @keyup.enter.native="getTableData" />
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="titleArea">
                <div class="newArea" v-if="scope.row.status == '新稿'"></div>
                <div class="tit" @click="handleTableRowBtn('撰稿', scope.row)">
                  <img v-if="scope.row.isStick" src="./img/置顶.png" alt="" style="margin-right: 3px; width: 10px" />
                  {{ scope.row.title }}
                </div>
                <div class="picArea" v-if="scope.row.type == '图片新闻'">
                  <img src="../../assets/img/picType.png" alt="" />
                </div>
                <div class="videoArea" v-if="scope.row.type == '视频新闻'">
                  <img src="../../assets/img/videoType.png" alt="" />
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="status" label="状态" width="100" :filters="[
            { text: '未发布', value: '未发布' },
            { text: '待审核', value: '待审核' },
            { text: '已发布', value: '已发布' },
          ]" :filter-method="filterStatus">
          </el-table-column>
          <el-table-column prop="type" label="新闻类型" width="100" :filters="[
            { text: '普通新闻', value: '普通新闻' },
            { text: '图片新闻', value: '图片新闻' },
            { text: '视频新闻', value: '视频新闻' },
            { text: '链接新闻', value: '链接新闻' },
          ]" :filter-method="filterType">
          </el-table-column>
          <el-table-column prop="creatTime" label="发布时间" width="160">
          </el-table-column>
          <el-table-column prop="creator" width="100" label="创建人">
          </el-table-column>
        </el-table>
        <!-- 弹出框 -->
        <div class="div_contextmenu_tip" v-if="tipData.flag" :style="tipData">
          <div class="content">
            <div class="bor" v-for="(but, i) in butArray" :key="i">
              <div class="line" v-has="item" v-for="(item, index) in but" :key="index" @click="butOper(item)">
                {{ item.text }}
                <i :class="item.icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-sizes="[16, 32, 48, 64]" :page-size="pageSize"
          layout="total,sizes,prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 右侧按钮框 -->
    <div ref="contentAreaButs" class="contentAreaButs">
      <div class="but" v-has="item" v-for="(item, index) in contentButArray" :key="index" @click="clickAllBut(item)">
        <i :class="item.icon"></i>
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { json, urlencoded } from "body-parser";
import hasDirective from "../../directives/has1.js";
import moment from "moment";
import * as docData from "./js/doc.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //每一行的按钮
      loading: false,
      drawTableRowBtn: [
        {
          type: "authorityData",
          label: "预览",
          page: "docLog",
        },
        {
          type: "authorityData",
          label: "回退",
          page: "docLog",
        },
        {
          type: "authorityData",
          label: "删除",
          page: "docLog",
        },
      ],
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "新建",
          page: "docInfo",
          class: "el-icon-plus itemBtn",
          method: "add",
          btnType: "1", //1表示普通按钮
        },
        {
          type: "authorityData",
          label: "导出",
          page: "docInfo",
          class: "el-icon-upload2 itemBtn",
          method: "export",
          btnType: "1", //1表示普通按钮
        },
        {
          type: "authorityData",
          label: "导入",
          page: "docInfo",
          class: "el-icon-download itemBtn",
          method: "import",
          btnType: "2", //2表示导入按钮
        },
        {
          type: "authorityData",
          label: "删除",
          page: "docInfo",
          class: "el-icon-delete itemBtn",
          method: "delete",
          btnType: "1",
        },
      ],
      //每一行的按钮
      rowBtn: [
        {
          type: "authorityData",
          label: "撰稿",
          page: "docInfo",
          status: "",
          icon: "el-icon-edit-outline",
        },
        {
          type: "authorityData",
          label: "日志",
          page: "docInfo",
          status: "",
          icon: "el-icon-date",
        },
        {
          type: "statusData",
          label: "发布",
          page: "docInfo",
          status: "",
          icon: "el-icon-s-promotion",
        },
        {
          type: "statusData",
          label: "引用",
          page: "docInfo",
          status: "",
          icon: "el-icon-connection",
        },
        {
          type: "authorityData",
          label: "预览",
          page: "docInfo",
          status: "",
          icon: "el-icon-view",
        },
        {
          type: "authorityData",
          label: "复制",
          page: "docInfo",
          status: "",
          icon: "el-icon-document-copy",
        },
        {
          type: "authorityData",
          label: "移动",
          page: "docInfo",
          status: "",
          icon: "el-icon-copy-document",
        },
      ],
      //抽屉显示标志
      drawerFlag: false,
      versionData: [
        {
          id: "",
          time: "2016-05-02",
          name: "王小虎",
        },
      ],
      operateData: [
        {
          id: "",
          time: "2016-05-02",
          name: "王小虎",
          operation: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      //每页显示多少条
      pageSize: 16,
      total: 1000,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      dialogVisible2: false,
      currentDialogData2: {
        title: "发布模式",
        listData: [
          {
            type: "select",
            name: "发布模式",
            value: "1",
            valueList: [
              {
                id: "1",
                name: "直接发布",
              },
              {
                id: "2",
                name: "定时发布",
              },
            ],
          },
          {
            type: "dateTime",
            name: "发布时间",
            value: "",
          },
        ],
      },
      copyOrCut: {
        flag: false, // 是否批量
        dialogVisible: false,
        title: "发布模式",
        site: "", // 当前所选站点ID
        siteList: [], // 所有站点列表
        column: [], // 当前所选栏目组
        columnList: [], // 当前所选站点下所有栏目
      },
      //弹出窗标志
      dialogVisible: false,

      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建文档",
        listData: [
          {
            type: "input",
            name: "文档名称",
            value: "",
            show: true,
          },
          // {
          //   type: "input",
          //   name: "文档唯一标识",
          //   label: "例如以英文字母缩写",
          //   value: "",
          // },
          // {
          //   type: "select",
          //   name: "文档模板",
          //   value: "",
          //   valueList: [
          //     {
          //       id: "1",
          //       name: "文档模板1",
          //     },
          //     {
          //       id: "2",
          //       name: "文档模板2",
          //     },
          //     {
          //       id: "3",
          //       name: "文档模板3",
          //     },
          //   ],
          // },
          {
            type: "select",
            name: "新闻类型",
            value: "",
            show: true,
            disable: false,
            valueList: [
              {
                id: "1",
                name: "普通新闻",
              },
              {
                id: "2",
                name: "图片新闻",
              },
              {
                id: "3",
                name: "视频新闻",
              },
              {
                id: "4",
                name: "链接新闻",
              },
            ],
          },
          {
            type: "input",
            name: "原新闻链接",
            value: "",
            show: false,
          },
        ],
      },
      currentPage: 1,
      search: "",
      tableData: [],
      //多选数据
      multipleSelection: [],
      listData: [
        {
          type: "input",
          name: "文档名称",
          value: "文档1",
        },
        {
          type: "input",
          name: "文档唯一标识",
          value: "文档1",
        },
        {
          type: "input",
          name: "文档发布地址",
          value: "文档域名",
        },
        {
          type: "select",
          name: "文档模板",
          value: "文档模板1",
          valueList: [
            {
              id: "1",
              name: "文档模板1",
            },
            {
              id: "2",
              name: "文档模板2",
            },
            {
              id: "3",
              name: "文档模板3",
            },
          ],
        },
        {
          type: "label",
          name: "文档状态",
          value: "已发布",
          valueList: [
            {
              id: "新稿",
              name: "新稿",
            },
            {
              id: "已发布",
              name: "已发布",
            },
            {
              id: "已作废",
              name: "已作废",
            },
          ],
        },
      ],
      btnData: [
        {
          label: "编辑稿件",
        },
        {
          label: "提交",
        },
        {
          label: "保存",
        },
        {
          label: "预览",
        },
        {
          label: "发布",
        },
        {
          label: "驳回",
        },
        {
          label: "撤稿",
        },
        {
          label: "导入",
        },
        {
          label: "导出",
        },
        {
          label: "删除",
        },
      ],
      userId: "",
      tipData: {
        // 弹出框内容
        flag: false,
        active: false,
        top: "",
        left: "",
      },
      butArray: docData.getContentMenuButArray("", false),
      contentButArray: docData.getContentButList(),
      sitckDialogData: {
        flag: false,
        title: "文档置顶",
        sitckFlag: 0,
        time: "",
      },
      revokeTimingkDialogData: {
        flag: false,
        title: "定时撤稿",
        time: "",
      },
      sequenceDialogData: {
        flag: false,
        title: "调整文档顺序",
        sequenceFlag: 0,
        index: "",
        total: "1230", // 本栏目的总条数
        oldIndex: "22", // 本文档的原位置
      },
      sequenceDocListDialogData: {
        flag: false,
        title: "调整文档顺序--选择指定文档",
        pageData: {
          pageSize: 8,
          currentPage: 1,
          total: 0,
        },
        tableData: [],
      },
      sequenceDocListDialogDataTableData: [],

      wendangzhuizongDialogData: {
        flag: false,
        title: "文档追踪情况",
      },

      copyDetails: {
        source: {
          site: "", // 来源站点d
          column: "", // 来源栏目
          time: "", // 复制时间
        },
        targetList: [
          {
            site: "", // 被复制站点
            column: "", // 被复制栏目
            time: "", // 复制时间
          },
        ],
      },
      quoteDetails: {
        source: {
          site: "", // 来源站点
          column: "", // 来源栏目
          time: "", // 引用时间
        },
        targetList: [
          {
            site: "", // 被引用站点
            column: "", // 被引用栏目
            time: "", // 引用时间
          },
        ],
      },
      moveDetails: {
        source: {
          site: "", // 来源站点
          column: "", // 来源栏目
          time: "", // 移动时间
        },
      },

      selectMuTabData: [],
    };
  },

  methods: {
    //确认发布
    sureRelease(flag) {
      // if (this.currentDialogData2.listData[0].value == "1") {
      let docIdList = [];
      this.multipleSelection.forEach((element) => {
        docIdList.push(element.id);
      });
      this.$request(
        "post",
        "/doc/release",
        {
          flag: flag,
          docIdList: docIdList,
          userId: "1",
          thisColumnId: this.$route.query.id,
          search: this.search,
        },
        (data) => {
          this.multipleSelection = [];
          this.$refs.multipleTable.clearSelection();
          this.$message.success("发布成功！");
          // this.$router.go(0);
          this.getTableData();
          // this.dialogVisible2 = false;
        }
      );
      // } else {
      //   this.$request(
      //     "post",
      //     "/regularRelease/releaseTiming",
      //     {
      //       id: this.currentRow.id,
      //       type: 3,
      //       releaseTime: moment(
      //         this.currentDialogData2.listData[1].value
      //       ).format("YYYY-MM-DD HH:mm:ss"),
      //     },
      //     (data) => {
      //       this.$message.success("定时发布成功！");
      //       this.dialogVisible2 = false;
      //     }
      //   );
      // }
    },
    /**
     * 导入文档成功
     */
    importSuccess(response, file, fileList) {
      //console.log(response.result);
      this.$message({
        message: "导入成功!",
        type: "success",
      });
      this.getTableData();
    },
    preview() {
      this.tipData.flag = false;
      this.tipData.active = false;
      this.$refs.multipleTable.setCurrentRow();
    },
    //版本列表按钮点击事件
    versionTableRowClick(type, row) {
      switch (type) {
        case "预览":
          this.$request(
            "post",
            "/docVersion/preview",
            {
              id: row.id,
            },
            (data) => {
              window.open(data.result.url, "_blank");
            }
          );
          break;
        case "回退":
          this.$request(
            "post",
            "/docVersion/rollback",
            {
              id: row.id,
            },
            (data) => {
              this.$message.success("回退成功");
              this.getStrawTable1Data();
            }
          );
          break;
        case "删除":
          this.$request(
            "post",
            "/docVersion/deleteDocVersion",
            {
              id: row.id,
            },
            (data) => {
              this.$message.success("删除成功");
              this.getStrawTable1Data();
            }
          );
          break;

        default:
          break;
      }
    },
    //获得右侧抽屉中文档版本数据
    getStrawTable1Data() {
      this.$request(
        "post",
        "/docVersion/getDocVersionList",
        {
          id: this.currentRow.id,
        },
        (data) => {
          this.versionData = data.result;
        }
      );
    },
    //获得右侧抽屉中操作记录数据
    getStrawTable2Data() {
      this.$request(
        "post",
        "/docVersion/getDocRecord",
        {
          id: this.currentRow.id,
        },
        (data) => {
          this.operateData = data.result;
          this.drawerFlag = true;
        }
      );
    },
    //获得表格数据
    getTableData() {
      // this.loading = true;
      this.$request(
        "post",
        "/doc/getList",
        {
          id: this.$route.query.id,
          current: this.currentPage,
          size: this.pageSize,
          search: this.search,
        },
        (data) => {
          this.tableData = data.result.records;
          for (let index = 0; index < this.tableData.length; index++) {
            const element = this.tableData[index];
            let rowBtn = JSON.parse(JSON.stringify(this.rowBtn));
            for (let i = 0; i < rowBtn.length; i++) {
              const ele = rowBtn[i];
              ele.status = element.status;
            }
            this.$set(element, "rowBtn", rowBtn);
          }
          //console.log("表格数据:", this.tableData);
          this.total = data.result.total;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      );
    },
    change(e) {
      this.$forceUpdate();
    },
    /**
     * 点击表格空白处触发事件
     */
    nullContextClick(event) {
      if (!this.tipData.active) {
        //console.log("其他");
        this.currentRow = {};
        this.butArray = docData.getNullContentMenuButArray();
        this.openTipData(event);
      }
    },
    /**
     * 表格右击事件
     */
    tableContextmenu(row, column, event) {
      // multipleTable
      this.$refs.multipleTable.setCurrentRow(row);
      this.tipData.active = true;
      this.currentRow = row;
      this.butArray = docData.getContentMenuButArray(row.status, row.isStick);
      //console.log("表格行", row, this.butArray);

      this.openTipData(event);
    },
    // 定位右击框
    openTipData(event) {
      this.tipData.flag = false;
      document.oncontextmenu = function () {
        //   关闭浏览器右击事件
        return false;
      };
      let pinmu = this.$getViewportSize();
      let clin = {
        height: this.$refs.tebleArea.offsetHeight,
        width: this.$refs.tebleArea.offsetWidth,
      };

      let leftBut = this.$refs.contentAreaButs.offsetWidth;

      let leftL = pinmu.width - clin.width - leftBut - 40;
      this.tipData.left = event.x - leftL + "px";

      let topL = pinmu.height - clin.height - 40;
      let cha = pinmu.height - event.y; //当前右击点的纵坐标与屏幕尺寸的差值,保证弹出框能在当前界面上显示全
      if (cha < 500) {
        let l = 500 - cha;
        this.tipData.top = event.y - topL - l + "px";
      } else {
        this.tipData.top = event.y - topL + "px";
      }
      this.tipData.flag = true;
    },
    clickAllBut(item) {
      this.currentRow = {};
      this.butOper(item);
    },
    /**
     * 右击操作框的操作事件
     */
    butOper(item) {
      if (this.currentRow.id) {
        // 在表格行中右击
        this.handleTableRowBtn(item.label, this.currentRow);
      } else {
        // 在空白处点击
        this.handleTableRowBtnNull(item.label);
      }
    },
    handleTableRowBtnNull(type) {
      switch (type) {
        case "刷新":
          this.getTableData();
          break;
        case "新建":
          this.createDoc();
          break;

        case "发布":
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先勾选要发布的文档!",
              type: "warning",
            });
            break;
          }
          this.sureRelease(false);
          break;
        case "复制":
          // this.copyOrCut.flag = true;
          this.copyOrCut.flag = false;
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先勾选要复制的文档!",
              type: "warning",
            });
            break;
          }
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档复制";
            this.copyOrCut.siteList = data.result;
            this.copyOrCut.column = [];
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;
        case "移动":
          // this.copyOrCut.flag = true;
          this.copyOrCut.flag = false;
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先勾选要移动的文档!",
              type: "warning",
            });
            break;
          }
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档移动";
            this.copyOrCut.siteList = data.result;
            this.copyOrCut.column = "";
            // this.copyOrCut.site = "";
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;
        case "引用":
          this.copyOrCut.flag = false;
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先勾选要引用的文档!",
              type: "warning",
            });
            break;
          }
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档引用";
            this.copyOrCut.siteList = data.result;
            this.copyOrCut.column = "";
            // this.copyOrCut.site = "";
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;

        case "删除":
          // this.multipleSelection = [];
          if (this.multipleSelection.length == 0) {
            this.$message({
              message: "请先勾选要删除的文档!",
              type: "warning",
            });
            break;
          }

          this.deleteDoc(false);
          break;

        case "预览":
          // this.multipleSelection = [];
          if (this.multipleSelection.length != 1) {
            this.$message({
              message: "请先勾选要预览的文档!",
              type: "warning",
            });
            break;
          }

          this.showDoc(this.multipleSelection[0].id);
          break;
        default:
          this.$alert("功能开发中...", "提示", {
            confirmButtonText: "确定",
            callback: (action) => { },
          });
          break;
      }
    },
    showDoc(id) {
      this.$request(
        "post",
        "/doc/directPreview",
        {
          id: id,
        },
        (data) => {
          window.open(data.result.previewUrl, "_blank");
        }
      );
    },
    handleTableRowBtn(type, row) {
      this.tipData.flag = false;
      this.currentRow = row;
      let routeData;
      let requestData = {};
      switch (type) {
        case "撰稿":
          if (row.type == "新闻引用") {
            this.$message({
              message: "引用文档,不可编辑!",
              type: "warning",
            });
            break;
          }
          routeData = this.$router.resolve({
            path: "/docUeditor",
            query: { id: row.id, columnId: this.$route.query.id },
          });
          window.open(routeData.href, "_blank");

          break;
        case "预览":
          this.$request(
            "post",
            "/doc/directPreview",
            {
              id: row.id,
            },
            (data) => {
              window.open(data.result.previewUrl, "_blank");
            }
          );
          break;
        case "发布":
          // this.dialogVisible2 = true;
          // this.multipleSelection = [];
          if (this.multipleSelection.length == 0) {
            this.multipleSelection.push(this.currentRow);
          }
          this.sureRelease(false);
          break;
        case "日志":
          this.getStrawTable1Data();
          this.getStrawTable2Data();
          break;
        case "复制":
          if (this.multipleSelection.length == 0) {
            this.multipleSelection.push(this.currentRow);
          }
          this.copyOrCut.flag = false;
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档复制";
            this.copyOrCut.siteList = data.result;
            // this.copyOrCut.site = "";
            this.copyOrCut.column = [];
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;
        case "移动":
          if (this.multipleSelection.length == 0) {
            this.multipleSelection.push(this.currentRow);
          }
          this.copyOrCut.flag = false;
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档移动";
            this.copyOrCut.siteList = data.result;
            // this.copyOrCut.site = "";
            this.copyOrCut.column = "";
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;
        case "引用":
          if (this.multipleSelection.length == 0) {
            this.multipleSelection.push(this.currentRow);
          }
          this.copyOrCut.flag = false;
          this.$request("post", "/site/getAllSite", { userId: 1 }, (data) => {
            this.copyOrCut.title = "文档引用";
            this.copyOrCut.column = "";
            this.copyOrCut.siteList = data.result;
            // this.copyOrCut.site = "";
            this.copyOrCut.site =
              data.result.length > 0 ? data.result[0].id : "";
            this.getColumnTree(this.copyOrCut.site);
          });
          break;
        case "刷新":
          this.getTableData();
          break;
        case "删除":
          // this.multipleSelection = [];
          if (this.multipleSelection.length == 0) {
            this.multipleSelection.push(this.currentRow);
          }
          this.deleteDoc(false);
          break;
        case "新建":
          this.createDoc();
          break;
        case "撤稿":
          this.$confirm(
            "此操作会将该文档从网站上撤回,若要重新查看该文档,需重新发布! 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$request(
                "post",
                "/doc/unpublish",
                {
                  id: this.currentRow.id,
                  userId: "1",
                },
                (data) => {
                  this.$message.success("撤稿成功!");
                  setTimeout(() => {
                    this.getTableData();
                  }, 500);
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消撤稿!",
              });
            });
          break;
        case "定时撤稿":
          this.revokeTimingkDialogData.time = "";
          this.revokeTimingkDialogData.flag = true;
          break;
        case "调整顺序":
          requestData = {
            docId: this.currentRow.id,
            columnId: this.$route.query.id,
            flag: this.currentRow.isStick ? "1" : "0", // 是否置顶
          };
          this.$request(
            "post",
            "/doc/getDocIndexInColumn",
            requestData,
            (data) => {
              this.sequenceDialogData.sequenceFlag = 0;
              this.sequenceDialogData.index = "";
              this.sequenceDialogData.oldIndex = data.result.index;
              this.sequenceDialogData.total = data.result.total;
              this.sequenceDialogData.flag = true;
            }
          );
          break;
        case "置顶":
          this.sitckDialogData.sitckFlag = 0;
          this.sitckDialogData.time = "";
          this.sitckDialogData.flag = true;
          break;
        case "取消置顶":
          this.$request(
            "post",
            "/doc/unstick",
            { docId: this.currentRow.id },
            (data) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.getTableData();
            }
          );
          break;

        case "文档追踪":
          this.$request(
            "post",
            "/docVersion/traceDoc",
            { docId: this.currentRow.id },
            (data) => {
              //console.log(data);
              this.copyDetails = data.result.copyDetails;
              this.moveDetails = data.result.moveDetails;
              this.quoteDetails = data.result.quoteDetails;
              this.wendangzhuizongDialogData.flag = true;
            }
          );
          break;
        default:
          break;
      }
      //console.log(row);
    },
    /**
     * 确定定时撤稿
     */
    revokeTiming() {
      let requestData = {
        id: this.currentRow.id,
        releaseTime: this.revokeTimingkDialogData.time,
        type: "3",
      };
      this.$request(
        "post",
        "/regularRelease/revokeTiming",
        requestData,
        (data) => {
          this.$message.success("操作成功!");
          this.revokeTimingkDialogData.flag = false;
          setTimeout(() => {
            this.getTableData();
          }, 500);
        }
      );
    },
    /**
     * 获取当前栏目下所有文档列表  用于指定文档排序
     */
    getDocListBySort() {
      let requestData = {
        current: this.sequenceDocListDialogData.pageData.currentPage,
        size: this.sequenceDocListDialogData.pageData.pageSize,
        columnId: this.$route.query.id,
        isStick: this.currentRow.isStick,
      };
      this.$request("post", "/doc/getPopupDoc", requestData, (data) => {
        //console.log("获取数据aaa:", data);
        this.sequenceDocListDialogDataTableData = data.result.tableData;
        this.sequenceDocListDialogData.pageData.total = data.result.total;
        this.$forceUpdate();
        setTimeout(() => {
          this.sequenceDialogData.flag = false;
          this.sequenceDocListDialogData.flag = true;
        }, 500);
      });
    },
    clickRow(row, column, event) {
      this.chooseTagDocIndex(row);
    },
    /**
     * 选择目标文档
     */
    chooseTagDocIndex(doc) {
      this.sequenceDialogData.index = doc.id;
      this.sureSequence(1);
      this.sequenceDocListDialogData.flag = false;
    },
    /**
     * 分页
     */
    handleSizeChangeDialog(val) {
      this.sequenceDocListDialogData.pageData.pageSize = val;
      this.getDocListBySort();
    },
    handleCurrentChangeDialog(val) {
      this.sequenceDocListDialogData.pageData.currentPage = val;
      this.getDocListBySort();
    },
    /**
     * 确定排序
     */
    sureSequence(flag) {
      let requestData = {
        docId: this.currentRow.id,
        columnId: this.$route.query.id,
        flag: flag,
        index: this.sequenceDialogData.index,
        isStick: this.currentRow.isStick,
      };
      if (
        requestData.flag == 0 &&
        !this.$formatNum.formatMathNumber(requestData.index)
      ) {
        this.$message({
          type: "error",
          message: "指定位置只能为正整数!",
        });
        return;
      }
      this.$request("post", "/doc/sortDocByIndex", requestData, (data) => {
        this.sequenceDialogData.flag = false;
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.getTableData();
      });
    },
    /**
     * 确定置顶
     */
    sureStick() {
      let requestData = {
        docId: this.currentRow.id,
        flag: this.sitckDialogData.sitckFlag,
        time: this.sitckDialogData.time,
        columnId: this.$route.query.id,
      };
      if (requestData.flag == 1 && requestData.time == "") {
        this.$message({
          type: "error",
          message: "请填写取消置顶的时间!",
        });
        return;
      }
      this.$request("post", "/doc/stick", requestData, (data) => {
        this.sitckDialogData.flag = false;
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.getTableData();
      });
    },
    /**
     * 批量删除文档
     */
    deleteDoc(flag) {
      let docIdList = [];
      this.multipleSelection.forEach((element) => {
        docIdList.push(element.id);
      });
      this.$confirm("确认删除该文档信息么?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/doc/delete",
            {
              flag: flag,
              userId: "1",
              docIdList: docIdList,
              thisColumnId: this.$route.query.id,
              search: this.search,
            },
            (data) => {
              this.multipleSelection = [];
              this.$refs.multipleTable.clearSelection();
              this.$message.success("删除成功！");
              this.getTableData();
            }
          );
        })
        .catch(() => {
          this.multipleSelection = [];
          this.$refs.multipleTable.clearSelection();
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 跳转文档编辑界面
    createDoc() {
      let routeData = this.$router.resolve({
        path: "/docUeditor",
        query: { id: "", columnId: this.$route.query.id },
      });
      window.open(routeData.href, "_blank");
    },
    // 获取当前所选站点的所有栏目树
    getColumnTree(siteId) {
      if (siteId == "") {
        this.copyOrCut.dialogVisible = true;
        return;
      }
      let requestData = {
        userId: 1,
        siteId: siteId,
      };
      this.$request(
        "post",
        "/column/getColumnTreeBySite",
        requestData,
        (data) => {
          this.copyOrCut.site = siteId;
          this.copyOrCut.columnList = data.result;
          this.copyOrCut.dialogVisible = true;
        }
      );
    },
    // 复制或移动确定事件
    sureCopyOrCut() {
      let requestData = {};
      let url = "";
      let docIdList = [];
      //console.log(this.multipleSelection);
      let flag = true;
      this.multipleSelection.forEach((element) => {
        docIdList.push(element.id);
        if (element.type == "新闻引用" && this.copyOrCut.title == "文档引用") {
          this.$message({
            message: "《" + element.title + "》为引用文档,不可被二次引用!",
            type: "warning",
          });
          flag = false;
        }
        if (!flag) {
          return;
        }
        if (element.status == "未发布" && this.copyOrCut.title == "文档引用") {
          this.$message({
            message: "《" + element.title + "》尚未发布,不可被引用!",
            type: "warning",
          });
          flag = false;
        }
      });
      if (!flag) {
        return;
      }
      if (this.copyOrCut.title == "文档复制") {
        requestData = {
          flag: this.copyOrCut.flag,
          userId: 1,
          // docId: this.currentRow.id,
          columnIds: this.copyOrCut.column,
          siteId: this.copyOrCut.site,
          docIdList: docIdList,
          thisColumnId: this.$route.query.id,
          search: this.search,
        };
        url = "/doc/copyDocToColumn";
      } else if (this.copyOrCut.title == "文档移动") {
        requestData = {
          flag: this.copyOrCut.flag,
          userId: 1,
          // docId: this.currentRow.id,
          columnId: this.copyOrCut.column,
          siteId: this.copyOrCut.site,
          docIdList: docIdList,
          thisColumnId: this.$route.query.id,
          search: this.search,
        };
        url = "/doc/moveDocToColumn";
      } else {
        requestData = {
          flag: this.copyOrCut.flag,
          userId: 1,
          // docId: this.currentRow.id,
          columnId: this.copyOrCut.column,
          siteId: this.copyOrCut.site,
          docIdList: docIdList,
          thisColumnId: this.$route.query.id,
          search: this.search,
        };
        url = "/doc/quoteDoc";
      }
      //console.log("确定复制或移动", requestData);
      this.$request("post", url, requestData, (data) => {
        this.copyOrCut.dialogVisible = false;
        this.multipleSelection = [];
        this.$refs.multipleTable.clearSelection();
        this.$message.success(this.copyOrCut.title + "成功!");
        this.getTableData();
      });
    },
    /**
     * 添加文档类型时,界面变化
     */
    changeType(val) {
      this.currentDialogData.listData[2].value = "";
      if (val == "4") {
        this.currentDialogData.listData[2].show = true;
      } else {
        this.currentDialogData.listData[2].show = false;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    filterType(value, row) {
      return row.type === value;
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "application/json") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isType) {
        this.$message.error("上传文件只能是json格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 5MB!");
      // }
      return isType;
    },

    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload1(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "application/json") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isType) {
        this.$message.error("上传文件只能是.json格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 5MB!");
      // }
      return isType;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["docInfo", "docLog"],
          relationId: this.$route.query.id,
          type: 2,
        },
        (data) => {
          if (
            data.result.docInfo.checkList == null ||
            data.result.docInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem(
            "docInfo",
            JSON.stringify({ docInfo: data.result.docInfo })
          );
          localStorage.setItem(
            "docLog",
            JSON.stringify({ docLog: data.result.docLog })
          );
        }
      );
    },
    handleClose3() {
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.copyOrCut.dialogVisible = false;
    },

    /**
     *
     */
    importError(err, file, fileList) {
      this.$message({
        message: "导入失败!",
        type: "error",
      });
    },
  },
  mounted() {
    this.userId = localStorage.getItem("userId");
    // 初始化文档列表
    this.getTableData();
  },
  beforeCreate() {
    localStorage.removeItem("docInfo");
    localStorage.removeItem("docLog");
  },
  created() {
    //console.log("清空localStorage");
    this.getCurrentAuthorityData();
    // let data = {
    //   docInfo: {
    //     name: "文档列表",
    //     checkList: ["源码", "撰稿", "日志", "新建", "导入", "导出", "删除"],
    //   },
    //   docLog: {
    //     name: "操作日志及文档版本",
    //     checkList: ["预览", "回退", "删除"],
    //   },
    // };
    // localStorage.setItem("docInfo", JSON.stringify({ docInfo: data.docInfo }));
    // localStorage.setItem("docLog", JSON.stringify({ docLog: data.docLog }));
  },
};
</script>

<style lang="less">
#docInfoTrue {
  height: 100%;
  width: 100%;
  display: flex;
  // flex-direction: column;
  // color: #2a9bf8;

  >.el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-dialog__body {
        height: auto;
        width: 100%;

        >.itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          >.leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          >.rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-select {
              width: 100%;
            }

            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }

      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  >.el-drawer__wrapper {
    .el-drawer__body {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      padding: 5px;
    }

    .versionArea {
      flex: 1;
      width: 100%;
      padding: 5px;
      margin-bottom: 50px;
      background-color: #f0f0f0;
    }

    .logArea {
      flex: 1;
      width: 100%;
      padding: 5px;
      background-color: #f0f0f0;
    }

    .tableBtnArea {
      display: flex;
    }
  }

  >.content {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;

    >.contentArea {
      width: 100%;
      flex: 1;
      overflow: auto;
      position: relative;
      display: flex;

      .el-table {
        position: absolute;
        min-width: 1000px;
        overflow: auto;

        .tableBtnArea {
          display: flex;
        }

        .titleArea {
          display: flex;
          // justify-content: center;
          align-items: center;
          position: relative;

          .newArea {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #01cb94;
            position: absolute;
            float: left;
            left: -10px;
            top: 5px;
          }

          .picArea {
            width: 32px;
            height: 32px;
          }

          .videoArea {
            width: 32px;
            height: 32px;
          }
        }

        .operateArea {
          display: flex;
          align-items: center;
          justify-content: space-between;

          >.btnArea {
            display: flex;
          }

          .itemBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.5s;
            margin: 0 3px;
            font-size: 1.5em;

            &:hover {
              color: #01cb94;
            }
          }

          >.input {
            width: 50%;
          }
        }

        .el-table__cell {
          padding: 3px 0;
        }
      }
    }

    >.block {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  >.contentAreaButs {
    width: 0;
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    background-color: #ffffff;
    border: 1px solid #eff1f7;
    align-items: center;

    >.but {
      width: 80%;
      height: auto;
      padding: 10px 6px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 16px;
      box-shadow: inset 0px 0px 5px 0px #96a5c9;
      box-shadow: 0px 0px 5px 0px #96a5c9;
      border-radius: 5px;
      cursor: pointer;

      >i {
        margin-right: 5px;
      }
    }
  }

  // 文档标题选中样式
  .firstColumn {
    .titleArea .tit {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      transition: 0.5s;
      display: flex;
      align-items: center;

      &:hover {
        border-bottom: 1px solid #0078d4;
        color: #0078d4;
      }

      >img {
        width: 10px;
      }
    }
  }

  .el-cascader {
    width: 100%;
  }

  .div_contextmenu_tip {
    float: left;
    position: absolute;
    z-index: 999;
    height: auto;
    min-height: 100px;
    width: 240px;
    max-height: 500px;
    background-color: #ffffff;
    box-shadow: 4px 4px 6px #0000005e;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;

    >.content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      >.bor {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        border-top: 1px solid #e4e4e4;
        margin-top: 8px;
        display: flex;
        flex-direction: column;

        &:nth-child(1) {
          border: none;
        }

        >.line {
          width: 100%;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding: 0 8px;
          box-sizing: border-box;
          // border-bottom: 1px solid #ebebeb;
          transition: 0.3s;

          &:hover {
            cursor: pointer;
            color: #82c5fb;
          }
        }
      }
    }
  }

  .zhiding {
    color: #2a9bf8;
    transition: 0.3s;
    cursor: pointer;
    border-bottom: 1px solid #00000000;

    &:hover {
      // text-decoration: underline;
      border-bottom: 1px solid #2a9bf8;
    }
  }

  .wdzz {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow-y: auto;

    >.item {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      >.title {
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;

        >.sp {
          width: 3px;
          height: 20px;
          background-color: #4eadfa;
          margin: 0 10px;
          margin-left: 20px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }

        >.te {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bolder;
        }
      }

      >.ly {
        width: 100%;
        height: auto;
        display: flex;

        >.tit {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          padding-right: 8px;
          box-sizing: border-box;
          font-weight: bolder;
        }

        >.va {
          width: 0;
          height: auto;
          flex: 1;
          display: flex;
          flex-direction: column;
          min-height: 40px;

          >.vv {
            height: 35px;
            display: flex;
            align-items: flex-start;
            font-size: 15px;

            >span {
              margin: 0 8px;
              font-size: 14px;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }

          >.bor {
            font-weight: bolder;
          }

          >.ti {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
