<template>
  <div id="templateInfo">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>{{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <el-table
        highlight-current-row
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%; height: 100%; overflow: auto"
        @selection-change="handleSelectionChange"
        @row-click="rowclick"
        @filter-change="filterChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column sortable prop="name" label="模板名称">
        </el-table-column>
        <el-table-column
          prop="type"
          label="模板类型"
          width="200"
          :filters="[
            { text: '站点模板', value: '1' },
            { text: '栏目模板', value: '2' },
            { text: '文档模板', value: '3' },
          ]"
          columnKey="type"
        >
        </el-table-column>
        <el-table-column width="400">
          <template
            slot="header"
            slot-scope="/* eslint-disable vue/no-unused-vars */ scope"
          >
            <div class="operateArea">
              <div class="btnArea">
                <el-tooltip
                  v-for="(item, index) in tableMenuBtn"
                  :key="index"
                  class="item"
                  effect="dark"
                  :content="item.label"
                  placement="top"
                >
                  <i
                    v-has="item"
                    @click="tableMenuBtnClick(item.method)"
                    :class="item.class"
                  ></i>
                </el-tooltip>
              </div>
              <el-input
                v-model="search"
                size="mini"
                placeholder="模板名称搜索"
                @keyup.enter.native="getTableData"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <div class="tableBtnArea">
              <el-button
                v-has="item"
                v-for="(item, index) in rowBtn"
                :key="index"
                size="mini"
                @click="handleTableRowBtn(item.label, scope.row)"
                >{{ item.label }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="btnArea">
      <div
        class="itemBtn"
        v-for="(item, index) in btnData"
        @click="btnClick(item, index)"
        :key="index"
        v-has="item"
      >
        <div v-if="item.btnType == 'ordinary'">{{ item.label }}</div>
        <div v-if="item.btnType == 'upload'">
          <el-upload
            class="avatar-uploader"
            :action="item.url"
            :show-file-list="false"
            :data="item.uploadData"
            :on-success="importSuccess"
            :before-upload="beforeAvatarUpload2"
            :on-error="importError"
            accept=".json"
          >
            {{ item.label }}
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //每一行的按钮
      rowBtn: [
        {
          type: "authorityData",
          label: "源码",
          page: "templateInfo",
        },
        {
          type: "authorityData",
          label: "预览",
          page: "templateInfo",
        },
      ],
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "新建",
          page: "templateInfo",
          class: "el-icon-plus itemBtn",
          method: "add",
        },
        {
          type: "authorityData",
          label: "删除",
          page: "templateInfo",
          class: "el-icon-delete itemBtn",
          method: "delete",
        },
      ],
      //分页数据
      pageSize: 10,
      total: 10,
      currentPage: 1,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建模板",
        listData: [
          {
            type: "input",
            name: "模板名称",
            value: "",
            isNotNull: true,
          },
          {
            type: "select",
            name: "模板类型",
            isNotNull: true,
            valueList: [
              {
                id: "1",
                name: "站点模板",
              },
              {
                id: "2",
                name: "栏目模板",
              },
              {
                id: "3",
                name: "文档模板",
              },
            ],
          },
        ],
      },

      search: "",
      fileType: [],
      tableData: [
        {
          id: "1",
          name: "模板1",
          type: "文档模板",
        },
      ],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "input",
          name: "模板名称",
          value: "",
          isNotNull: true,
        },
        {
          type: "select",
          name: "模板类型",
          isNotNull: true,
          value: "",
          valueList: [
            {
              id: "1",
              name: "站点模板",
            },
            {
              id: "2",
              name: "栏目模板",
            },
            {
              id: "3",
              name: "文档模板",
            },
          ],
        },
      ],
      btnData: [
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "templateInfo",
          label: "保存",
        },
        // {
        //   btnType: "upload",
        //   type: "authorityData",
        //   page: "templateInfo",
        //   label: "导入",
        //   url: this.$baseurl + "/template/import",
        //   uploadData: {
        //     systemName: "mhwz",
        //     path: "",
        //     title: "导入栏目",
        //     columnId: this.$route.query.id,
        //   },
        // },
        // {
        //   btnType: "ordinary",
        //   type: "authorityData",
        //   page: "templateInfo",
        //   label: "导出",
        // },
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "templateInfo",
          label: "删除",
        },
      ],
      multipleSelection: [],
    };
  },
  mounted() {
    // 初始化文档列表
    this.getTableData();
  },
  methods: {
    //不同类型模板预览,提供不同的弹窗和下拉列表
    templatePreView(type) {
      this.currentDialogData = {
        title: type + "预览",
        listData: [
          {
            type: "select",
            name: "选择",
            valueList: [],
          },
        ],
      };
      switch (type) {
        case "站点模板":
          this.$request(
            "post",
            "/site/getSiteInfo",
            {
              id: this.$route.query.id,
            },
            (data) => {
              this.currentDialogData.listData[0].valueList = [
                {
                  id: this.$route.query.id,
                  name: data.result.name,
                },
              ];
            }
          );
          break;
        case "栏目模板":
          this.$request(
            "post",
            "/column/getColumnList",
            {
              id: this.$route.query.id,
            },
            (data) => {
              this.currentDialogData.listData[0].valueList = data.result;
            }
          );
          break;
        case "文档模板":
          this.$request(
            "post",
            "/doc/getLatelyDoc",
            {
              id: this.$route.query.id,
            },
            (data) => {
              this.currentDialogData.listData[0].valueList = data.result;
            }
          );
          break;

        default:
          break;
      }
    },
    //表格行按钮点击事件
    handleTableRowBtn(type, row) {
      this.currentRow = row;
      switch (type) {
        case "源码":
          let routeData = this.$router.resolve({
            path: "/templateCodeMirror",
            query: {
              id: this.$route.query.id,
              templateId: row.id,
              type: row.type,
            },
          });
          window.open(routeData.href, "_blank");
          break;
        case "预览":
          this.templatePreView(row.type);
          this.dialogVisible = true;
          break;
        default:
          break;
      }
      //console.log(row);
    },
    filterType(value, row) {
      // //console.log(value);
      return row.type === value;
    },
    filterChange(filters) {
      //console.log("sss", filters);
      this.fileType = filters.type;
      //console.log("sss", filters, this.fileType);
      this.getTableData();
    },
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/template/getTemplateBySiteId",
        {
          siteId: this.$route.query.id,
          current: this.currentPage,
          size: this.pageSize,
          type: this.fileType,
          search: this.search,
        },
        (data) => {
          this.tableData = data.result.records;
          this.total = data.result.total;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 删除关键字并刷新
    deleteKeyWord(id) {
      this.$request(
        "post",
        "/site/deleteKeyWord",
        {
          id: id,
        },
        (data) => {
          this.currentRow = {};
          this.getTableData();
        }
      );
    },
    //底部按钮点击事件
    btnClick(item, index) {
      switch (item.label) {
        case "保存":
          if (this.listData[0].value == "") {
            this.$message.error("模板名称不能为空!");
            return;
          }
          if (this.listData[1].value == "") {
            this.$message.error("模板类型不能为空!");
            return;
          }

          if (this.listData[0].value.length > 50) {
            this.$message.error("模板名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          this.$request(
            "post",
            "/template/updateTemplate",
            {
              templateId: this.currentRow.id,
              name: this.listData[0].value,
              type: this.listData[1].value,
              code: null,
            },
            (data) => {
              this.$message.success("保存成功");
              this.getTableData();
            }
          );
          break;
        case "删除":
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/template/deleteTemplate",
                {
                  idList: [{ id: this.currentRow.id }],
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        // case "导出":
        //   window.open(
        //     this.$baseurl + "/template/export?id=" + this.$route.query.id,
        //     "_blank"
        //   );
        //   break;
        default:
          break;
      }
    },
    //行点击事件
    rowclick(row, colum, event) {
      this.currentRow = row;
      this.listData[0].value = row.name;
      let type = "";
      switch (row.type) {
        case "站点模板":
          type = "1";
          break;
        case "栏目模板":
          type = "2";
          break;
        case "文档模板":
          type = "3";
          break;

        default:
          break;
      }
      this.listData[1].value = type;
    },
    //表格顶部右上侧按钮点击事件
    tableMenuBtnClick(type) {
      switch (type) {
        //新建文档
        case "add":
          this.dialogVisible = true;
          this.currentDialogData = {
            title: "新建模板",
            listData: [
              {
                type: "input",
                name: "模板名称",
                value: "",
                isNotNull: true,
              },
              {
                type: "select",
                name: "模板类型",
                isNotNull: true,
                valueList: [
                  {
                    id: "1",
                    name: "站点模板",
                  },
                  {
                    id: "2",
                    name: "栏目模板",
                  },
                  {
                    id: "3",
                    name: "文档模板",
                  },
                ],
              },
            ],
          };
          break;
        case "delete":
          if (this.multipleSelection.length == 0) {
            this.$message({
              type: "error",
              message: "未选择任何信息!",
            });
            return;
          }
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/template/deleteTemplate",
                {
                  idList: this.multipleSelection,
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建模板":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("模板名称不可为空!");
            return;
          }
          if (this.currentDialogData.listData[1].value == "") {
            this.$message.error("模板类型不可为空!");
            return;
          }

          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("模板名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          this.$request(
            "post",
            "/template/addTemplate",
            {
              name: this.currentDialogData.listData[0].value,
              siteId: this.$route.query.id,
              type: this.currentDialogData.listData[1].value,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建模板成功!");
              this.getTableData();
            }
          );
          break;
        case "文档模板预览":
          this.$request(
            "post",
            "/template/docTemplatePreview",
            {
              templateId: this.currentRow.id,
              docId: this.currentDialogData.listData[0].value,
              code: null,
            },
            (data) => {
              window.open(data.result.link, "_blank");
            }
          );
          break;
        case "栏目模板预览":
          this.$request(
            "post",
            "/template/columnTemplatePreview",
            {
              templateId: this.currentRow.id,
              columnId: this.currentDialogData.listData[0].value,
              code: null,
            },
            (data) => {
              window.open(data.result.link, "_blank");
            }
          );
          break;
        case "站点模板预览":
          this.$request(
            "post",
            "/template/siteTemplatePreview",
            {
              templateId: this.currentRow.id,
              docId: this.currentDialogData.listData[0].value,
              code: null,
            },
            (data) => {
              window.open(data.result.link, "_blank");
            }
          );
        default:
          break;
      }
      this.dialogVisible = false;
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    // filterType(value, row) {
    //   return row.type === value;
    // },

    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["templateInfo"],
          relationId: this.$route.query.id,
          type: 0,
        },
        (data) => {
          if (
            data.result.templateInfo.checkList == null ||
            data.result.templateInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("templateInfo", JSON.stringify(data.result));
        }
      );
    },

    /**
     * 导入栏目成功
     */
    importSuccess(response, file, fileList) {
      this.$message({
        message: "导入成功!",
        type: "success",
      });
      this.getTableData();
    },
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload2(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "application/json") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传文件只能是json格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 2MB!");
      // }
      return isType;
    },
    /**
     *
     */
    importError(err, file, fileList) {
      this.$message({
        message: "导入失败!",
        type: "error",
      });
    },
  },
  beforeCreate() {
    localStorage.removeItem("templateInfo");
  },
  //在created中拿到当前页面的权限数据
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   templateInfo: {
    //     name: "站点关键字",
    //     checkList: ["新建", "保存", , "导入", "导出", "删除"],
    //   },
    // };
    // localStorage.setItem("templateInfo", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#templateInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .el-table {
      position: absolute;
      min-width: 1000px;
      overflow: auto;
      .tableBtnArea {
        display: flex;
      }
      .titleArea {
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        .newArea {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01cb94;
          position: absolute;
          float: left;
          left: -10px;
          top: 5px;
        }
        .picArea {
          width: 32px;
          height: 32px;
        }
        .videoArea {
          width: 32px;
          height: 32px;
        }
      }
      .operateArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .btnArea {
          display: flex;
        }
        .itemBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.5s;
          margin: 0 3px;
          &:hover {
            color: #01cb94;
          }
        }
      }
      .el-table__cell {
        padding: 3px 0;
      }
    }
  }
  > .block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>