<template>
  <div id="assemblyManage">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>{{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="leftArea">
      <div class="leftAreaContent">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>
        <el-tree
          ref="assemblyTree"
          :filter-node-method="filterNode"
          :data="assemblyData"
          :props="defaultProps"
          highlight-current
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          node-key="id"
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>
              <img src="@/assets/img/站点.png" alt="" />

              {{ node.label }}
            </span>
          </span>
        </el-tree>
      </div>

      <div class="leftAreaBut">
        <div
          class="but"
          @click="addNew(item)"
          v-for="(item, index) in menuBut"
          :key="index"
        >
          {{ item.name }}
        </div>
        <div :class="{ btn: true, btnHide: btnHide }">
          <i class="el-icon-document-add"> </i>
        </div>
      </div>
    </div>
    <div class="assemblyContentArea">
      <div id="assemblyMenu">
        <div class="topArea">
          <div
            @click="menuClick(item, index)"
            :class="{ itemMenu: true, itemMenuActive: currentIndex == index }"
            v-for="(item, index) in menuData"
            :key="index"
          >
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="contentArea">
          <div class="linkArea">
            <div
              class="itemLinkArea"
              v-for="(item, index) in navigationData"
              :key="index"
            >
              <div><i class="el-icon-d-arrow-right"></i></div>
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="detailArea">
            <router-view @update="update" v-if="isRouterAlive"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as assemblyUtils from "./assembly.js";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      btnHide: false,
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      isRouterAlive: true,
      filterText: "",
      userData: { userId: "1" },
      //菜单数据
      currentIndex: 0,
      menuData: [
        {
          id: "",
          name: "组件信息",
        },
      ],

      // 站点数据
      assemblyData: [],
      // 树节点的默认数据key
      defaultProps: {
        children: "children",
        label: "name",
      },
      //下方按钮数据
      menuBut: [{ name: "新建组件", active: "createassembly" }],

      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "",
        listData: [],
      },
      //dialog所有数据 根据按钮在其中去切换
      dialogData: [
        {
          title: "新建组件",
          listData: [
            {
              type: "input",
              name: "组件名称",
              value: "",
              isNotNull: true,
            },
            {
              type: "input",
              name: "组件唯一标识",
              value: "",
              isNotNull: true,
            },
          ],
        },
        {
          title: "新建栏目组件",
          listData: [
            {
              type: "input",
              name: "栏目组件名称",
              value: "",
              isNotNull: true,
            },
          ],
        },
        {
          title: "新建文档组件",
          listData: [
            {
              type: "input",
              name: "文档组件名称",
              value: "",
              isNotNull: true,
            },
          ],
        },
      ],

      //导航栏数据
      navigationData: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.assemblyTree.filter(val);
    },
  },
  methods: {
    /**
     * 子级路由触发更新
     */
    update($event) {
      let type = $event.type;
      //console.log($event);
      switch (type) {
        case "updateAssembly":
          this.$request(
            "post",
            "/assembly/getAssemblyList",
            {
              userId: this.userData.userId,
            },
            (data) => {
              if (data.result.length > 0) {
                this.assemblyData = data.result;
                this.$nextTick(() => {
                  this.$refs.assemblyTree.setCurrentKey($event.id);
                });
                this.$router.push({
                  path: "/assemblyManage/assemblyInfo",
                  query: { id: $event.id },
                });
              }
            }
          );
          break;
        case "deleteAssembly":
          this.$request(
            "post",
            "/assembly/getAssemblyList",
            {
              userId: this.userData.userId,
            },
            (data) => {
              this.assemblyData = data.result;
              if (this.assemblyData.length > 0) {
                //如果数组长度>0 则进行默认选中第一项
                this.$nextTick(() => {
                  this.$refs.assemblyTree.setCurrentKey(
                    this.assemblyData[0].id
                  );
                });
                this.$router.push({
                  path: "/assemblyManage/assemblyInfo",
                  query: {
                    id: this.assemblyData[0].id,
                  },
                });
                this.reload();
              }
            }
          );
          break;
        case "updateColumnInfo":
          this.$request(
            "post",
            "/assembly/getAssemblyList",
            {
              userId: this.userData.userId,
            },
            (data) => {
              this.assemblyData = data.result;
              this.$nextTick(() => {
                this.$refs.assemblyTree.setCurrentKey($event.id);
              });
              this.$router.push({
                path: "/assemblyManage/columnInfo",
                query: { id: $event.id },
              });
            }
          );
          break;
        case "deleteColumn":
          this.$request(
            "post",
            "/assembly/getAssemblyList",
            {
              userId: this.userData.userId,
            },
            (data) => {
              this.assemblyData = data.result;
              this.$nextTick(() => {
                this.$refs.assemblyTree.setCurrentKey(this.assemblyData[0].id);
              });
              this.$router.push({
                path: "/assemblyManage/assemblyInfo",
                query: { id: this.assemblyData[0].id },
              });
              this.reload();
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建组件":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("组件名称不能为空!");
            return;
          }
          if (this.currentDialogData.listData[1].value == "") {
            this.$message.error("组件唯一标识不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("组件名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[1].value.length > 100) {
            this.$message.error(
              "组件唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/assembly/addAssembly",
            {
              name: this.currentDialogData.listData[0].value,
              assemblyOnly: this.currentDialogData.listData[1].value,
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.currentDialogData.listData[0].value = "";
              this.currentDialogData.listData[1].value = "";
              this.$message.success("新建组件成功!");
              //新建成功后 刷新站点
              this.$request(
                "post",
                "/assembly/getAssemblyList",
                {
                  userId: this.userData.userId,
                },
                (data) => {
                  if (data.result.length > 0) {
                    this.assemblyData = data.result;
                    this.$nextTick(() => {
                      this.$refs.assemblyTree.setCurrentKey(
                        this.assemblyData[0].id
                      );
                    });
                    this.$router.push({
                      path: "/assemblyManage/assemblyInfo",
                      query: {
                        id: this.assemblyData[0].id,
                      },
                    });
                    this.reload();
                  }
                }
              );
            }
          );
          break;
        case "新建栏目组件":
          this.$request(
            "post",
            "/assembly/addassembly",
            {
              name: this.currentDialogData.listData[0].value,

              type: "2",
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建栏目组件成功!");
              //新建成功后 刷新站点树
              this.$request(
                "post",
                "/assembly/getAssemblyList",
                {
                  userId: this.userData.userId,
                },
                (data) => {
                  this.assemblyData = data.result;
                  this.$nextTick(() => {
                    this.$refs.assemblyTree.setCurrentKey(this.$route.query.id);
                  });
                }
              );
            }
          );
          break;
        case "新建文档组件":
          this.$request(
            "post",
            "/assembly/addassembly",
            {
              name: this.currentDialogData.listData[0].value,

              type: "3",
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建文档组件成功!");
              //新建成功后 刷新站点树
              this.$request(
                "post",
                "/assembly/getAssemblyList",
                {
                  userId: this.userData.userId,
                },
                (data) => {
                  this.assemblyData = data.result;
                  this.$nextTick(() => {
                    this.$refs.assemblyTree.setCurrentKey(this.$route.query.id);
                  });
                }
              );
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    //创建新站点/新栏目/新子栏目
    addNew(item) {
      switch (item.name) {
        case "新建组件":
          this.currentDialogData.title = this.dialogData[0].title;
          this.currentDialogData.listData = this.dialogData[0].listData;
          break;
        case "新建栏目组件":
          this.currentDialogData.title = this.dialogData[1].title;
          this.currentDialogData.listData = this.dialogData[1].listData;
          break;
        case "新建文档组件":
          this.currentDialogData.title = this.dialogData[2].title;
          this.currentDialogData.listData = this.dialogData[2].listData;
          break;
        default:
          break;
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 用作路由刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    /**
     * 站点筛选
     */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    columnClick(index) {
      this.columnIndex = index;
    },
    childClick(index) {
      this.childIndex = index;
    },
    menuClick(item, index) {
      this.currentIndex = index;
    },
    // 树节点点击事件
    handleNodeClick(data, node, item) {
      this.judgeMenuButShow(data, data.id);
    },
    judgeMenuButShow(data, id) {
      this.menuBut = [{ name: "新建组件", active: "createassembly" }];
      switch (data.flag) {
        case "1":
          // 点击的是站点组件相关节点
          this.menuBut = [{ name: "新建组件", active: "createassembly" }];
          break;
        case "2":
          // 点击的是栏目组件相关节点
          this.menuBut = [{ name: "新建栏目组件", active: "createassembly" }];

          break;
        case "3":
          // 点击的是文档组件相关节点
          this.menuBut = [{ name: "新建文档组件", active: "createassembly" }];

          break;

        default:
          break;
      }
      //如果是具体的组件,则获得组件信息 -1 -2 -3为三个头结点的id,不需要获取组件信息
      if (id != "-1" && id != "-2" && id != "-3") {
        this.$router.push({
          path: "/assemblyManage/assemblyInfo",
          query: { id: id, flag: data.flag },
        });
        this.reload();
      }
    },
  },
  mounted() {
    // 获得组件树
    this.$request(
      "post",
      "/assembly/getAssemblyList",
      {
        userId: this.userData.userId,
      },
      (data) => {
        this.assemblyData = data.result;
        if (this.assemblyData.length > 0) {
          //如果数组长度>0 则进行默认选中第一项
          this.$nextTick(() => {
            this.$refs.assemblyTree.setCurrentKey(this.assemblyData[0].id);
          });
          this.$router.push({
            path: "/assemblyManage/assemblyInfo",
            query: {
              id: this.assemblyData[0].id,
            },
          });
        }
      }
    );
  },
};
</script>

<style lang="less">
#assemblyManage {
  width: 100%;
  height: 100%;
  display: flex;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .leftArea {
    flex: 2.2;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-left: 2px solid #f0f3fc;
    box-sizing: border-box;
    > .leftAreaContent {
      width: 100%;
      height: 0;
      flex: 12;
      overflow: auto;
      margin-top: 10px;
      .el-input {
        padding: 5px;
      }
      .el-input__inner {
        height: 30px;
        border-radius: 10px;
        // opacity: 0.5;
      }
      .el-input__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .custom-tree-node {
        // padding-left: 15px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .el-tree-node__expand-icon {
        // display: none;
        font-size: 1.3em;
      }

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #f5f5f5ef;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 5px;
        background-color: #f5f5f500;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #dee8fe;
      }

      .el-tree-node__content {
        height: 50px !important;
      }
      .is-current > .el-tree-node__content {
        background-color: #dee8fe !important;
      }
    }
    > .leftAreaBut {
      width: 100%;
      height: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      &:hover {
        > .btn {
          // width: 80px;
          // height: 30px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // border: 1px solid #5d7bb5;
          // border-radius: 10px;
          // transition: 0.5s;
          // font-size: 12px
          &:hover {
            // transform: scale(1.1);
            cursor: pointer;
            // color: #ffffff;
            // background-color: #5d7bb5;
            // border: 1px solid #ffffff;
          }
        }
        .but {
          // color: red;
          width: 80px;
          opacity: 1;
        }
      }
      > .btn {
        // width: 80px;
        // height: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // border: 1px solid #5d7bb5;
        // border-radius: 10px;
        // transition: 0.5s;
        // font-size: 12px;
        font-size: 1.5em;
        transition: 0.5s;
      }
      > .but {
        width: 80px;
        height: 30px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid #5d7bb5;
        border-radius: 10px;
        font-size: 12px;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background-color: #5d7bb5;
          border: 1px solid #ffffff;
        }
      }
      > .btnHide {
        display: none;
      }
    }
  }
  > .assemblyContentArea {
    flex: 10;
    height: 100%;
    overflow: hidden;
    > #assemblyMenu {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      > .topArea {
        flex: 1;
        width: 100%;
        display: flex;
        background-color: white;
        > .itemMenu {
          flex: 1;
          max-width: 200px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          &:hover {
            cursor: pointer;
            background-color: #0042e0;
            color: white;
            &::after {
              position: absolute;
              bottom: 0;
              content: "";
              width: 80%;
              height: 3px;
              background-color: #fa8100;
            }
          }
        }
        > .itemMenuActive {
          background-color: #0042e0;
          color: white;
          &::after {
            position: absolute;
            bottom: 0;
            content: "";
            width: 80%;
            height: 3px;
            background-color: #fa8100;
          }
        }
      }
      > .contentArea {
        flex: 12;
        width: 100%;
        // background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > .linkArea {
          display: flex;
          height: auto;
          width: 100%;
          background-color: white;
          > .itemLinkArea {
            height: auto;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
              height: 21px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            > div:nth-child(2) {
              &:hover {
                color: #0042e0;
                cursor: pointer;
              }
            }
          }
        }
        > .detailArea {
          flex: 1;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>