/**
 * 获取站点对应菜单
 */
export function getSiteMenuList(that, id) {
    // 调用接口

    that.menuData = [{
            id: "",
            name: "站点信息",
        },
        {
            id: "",
            name: "栏目回收站",
        },
        {
            id: "",
            name: "文档回收站",
        },
        {
            id: "",
            name: "上传/附件设置",
        },
        {
            id: "",
            name: "智能建站",
        },
    ];
}

/**
 * 获取只有子栏目的栏目对应菜单
 */
export function getColumnMenuList(that, id) {
    // 调用接口

    that.menuData = [{
            id: "",
            name: "栏目信息",
        },
        // {
        //     id: "",
        //     name: "栏目回收站",
        // },
    ];
}

/**
 * 获取只有文档的栏目对应菜单
 */
export function getColumnMenuList2(that, id) {
    // 调用接口
    that.menuData = [{
        id: "",
        name: "文档列表",
    }, {
        id: "",
        name: "栏目信息",
    },{
        id: "",
        name: "数据统计",
    }, ];
}