var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-flex-center",attrs:{"id":"login"}},[_c('div',{staticClass:"login-re"},[_c('div',{staticClass:"left-in"},[_c('div',{staticClass:"outer"},_vm._l((_vm.outList),function(it,i){return _c('div',{key:i,staticClass:"out"},[_c('a',{attrs:{"href":it.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(it.name)+" ")])])}),0)]),_c('div',{staticClass:"right-in"},[_c('div',{staticClass:"content div-flex-column-center",attrs:{"element-loading-background":"rgba(255, 255, 255, 0.3)"}},[_vm._m(0),_c('div',{staticClass:"hyndl"},[_vm._v("欢迎您登录")]),_c('div',{staticClass:"line div-flex-center"},[_c('div',{staticClass:"line-value div-flex-center"},[(_vm.flag == '0')?_c('el-input',{attrs:{"placeholder":"请输入登录账号","prefix-icon":"el-icon-user","clearable":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):_vm._e()],1)]),_c('div',{staticClass:"line div-flex-center"},[_c('div',{staticClass:"line-value div-flex-center"},[(_vm.flag == '0')?_c('el-input',{attrs:{"placeholder":"请输入登录密码","prefix-icon":"el-icon-lock","show-password":"","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()],1),(_vm.flag == '1')?_c('div',{class:{
            'line-but': true,
            'div-flex-center': true,
            'line-but-disable': _vm.isDisable,
          },on:{"click":_vm.getVerification}},[_vm._v(" "+_vm._s(_vm.butTip)+" ")]):_vm._e()]),_c('Vcode',{attrs:{"show":_vm.isShow},on:{"success":_vm.success,"close":_vm.close}}),_c('div',{staticClass:"line-login div-flex-center",on:{"click":_vm.login}},[_vm._v("登录")]),_vm._m(1)],1),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title div-flex-center"},[_c('img',{attrs:{"src":require("../assets/img/sgslogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-txt"},[_c('span',{staticClass:"lxtxt"},[_c('img',{attrs:{"src":"http://soft-ats.com/images/cz/wxlx.png"}})]),_c('span',{staticClass:"lxtxt"},[_c('img',{attrs:{"src":"http://soft-ats.com/images/cz/%E5%BC%9B%E6%8C%AF%E7%A7%91%E6%8A%80%E5%85%AC%E4%BC%97%E5%8F%B7%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"botm"},[_vm._v(" Copyright ©安徽弛振科技有限公司     "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn"}},[_vm._v("皖ICP备19014873号-3")])])
}]

export { render, staticRenderFns }