export function formatMathDecimal(number) {
    var numReg = /^[\+\-]?\d*?\.?\d*?$|^([^0][0-9]+|0)$/
    var numRe = new RegExp(numReg)
    if (!numRe.test(number)) {
        return false
    }
    return true
}

/**
 * 校验正整数
 * @param {*} number 
 */
export function formatMathNumber(number) {
    var reg = /^[1-9]\d*$/
    var numRe = new RegExp(reg)
    if (!numRe.test(number)) {
        return false
    }
    return true
}