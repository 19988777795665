<template>
  <div id="taskInfo">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <el-table
        highlight-current-row
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%; height: 100%; overflow: auto"
        @selection-change="handleSelectionChange"
        @row-click="rowclick"
        @filter-change="filterTag"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="任务名称"> </el-table-column>
        <el-table-column prop="releaseTime" label="任务时间" width="200">
        </el-table-column>
        <el-table-column
          prop="type"
          label="任务类型"
          width="200"
          column-key="type"
          :filters="[
            { text: '发布任务', value: '1' },
            { text: '撤稿任务', value: '2' },
            { text: '限时置顶', value: '3' },
          ]"
          :filter-multiple="false"
        >
        </el-table-column
        ><el-table-column sortable prop="status" label="任务状态" width="200">
        </el-table-column
        ><el-table-column sortable prop="userName" label="创建人" width="200">
        </el-table-column>
        <el-table-column width="200">
          <template
            slot="header"
            slot-scope="/* eslint-disable vue/no-unused-vars */ scope"
          >
            <div class="operateArea">
              <div class="btnArea">
                <el-tooltip
                  v-for="(item, index) in tableMenuBtn"
                  :key="index"
                  class="item"
                  effect="dark"
                  :content="item.label"
                  placement="top"
                >
                  <i
                    v-has="item"
                    @click="tableMenuBtnClick(item.method)"
                    :class="item.class"
                  ></i>
                </el-tooltip>
              </div>
              <el-input
                v-model="search"
                size="mini"
                placeholder="任务名称搜索"
                @keyup.enter.native="getTableData"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
            :disabled="item.status == '已完成'"
          >
          </el-date-picker>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="btnArea">
      <div
        class="itemBtn"
        v-for="(item, index) in btnData"
        @click="btnClick(item, index)"
        :key="index"
        v-has="item"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
import moment from "moment";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "立即发布",
          page: "taskInfo",
          class: "el-icon-s-promotion itemBtn",
          method: "release",
        },
        {
          type: "authorityData",
          label: "删除",
          page: "taskInfo",
          class: "el-icon-delete itemBtn",
          method: "delete",
        },
      ],
      //分页数据
      pageSize: 10,
      total: 10,
      currentPage: 1,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建站点关键字",
        listData: [
          {
            type: "dateTime",
            name: "关键字",
            value: "",
          },
          {
            type: "textarea",
            name: "关键字内容",
            value: "",
          },
        ],
      },
      search: "",
      type: "",
      tableData: [
        {
          id: "1",
          name: "任务名称",
          releaseTime: "2022-04-9 19:48:49",
          status: "任务状态",
          userName: "用户名称",
        },
      ],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "label",
          name: "任务名称",
          value: "",
        },
        {
          type: "dateTime",
          name: "任务时间",
          value: "",
          status: "",
        },
      ],
      btnData: [
        {
          type: "authorityData",
          page: "taskInfo",
          label: "立即发布",
        },
        {
          type: "authorityData",
          page: "taskInfo",
          label: "保存",
        },
        {
          type: "authorityData",
          page: "taskInfo",
          label: "删除",
        },
      ],
      multipleSelection: [],
    };
  },
  mounted() {
    // 初始化文档列表
    this.getTableData();
  },
  methods: {
    filterTag(filters) {
      //console.log(filters);
      this.type = filters.type[0];
      this.getTableData();
    },
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/regularRelease/getReleaseList",
        {
          id: this.$route.query.id,
          current: this.currentPage,
          size: this.pageSize,
          search: this.search,
          type: this.type,
        },
        (data) => {
          this.tableData = data.result.records;
          this.total = data.result.total;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 删除关键字并刷新
    deleteKeyWord(id) {
      this.$request(
        "post",
        "/site/deleteKeyWord",
        {
          id: id,
        },
        (data) => {
          this.currentRow = {};
          this.getTableData();
        }
      );
    },
    //底部按钮点击事件
    btnClick(item, index) {
      switch (item.label) {
        case "立即发布":
          if (this.multipleSelection.length == 0) {
            this.$message({
              type: "error",
              message: "未选择任何信息!",
            });
            return;
          }
          this.$request(
            "post",
            "/regularRelease/directRelease",
            {
              userId: "1",
              idList: this.multipleSelection,
            },
            (data) => {
              this.$message.success("发布成功!");
              this.getTableData();
            }
          );
          break;
          break;
        case "保存":
          this.$request(
            "post",
            "/regularRelease/updateTask",
            {
              userId: "1",
              id: this.currentRow.id,
              name: this.listData[0].value,
              releaseTime: moment(this.listData[1].value).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
            },
            (data) => {
              this.$message.success("保存成功!");
              this.getTableData();
            }
          );
          break;
        case "删除":
          if (this.multipleSelection.length == 0) {
            this.$message({
              type: "error",
              message: "未选择任何信息!",
            });
            return;
          }
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/regularRelease/deleteRelease",
                {
                  userId: "1",
                  idList: this.multipleSelection,
                },
                (data) => {
                  this.$message.success("删除成功!");
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
    //行点击事件
    rowclick(row, colum, event) {
      this.currentRow = row;
      this.listData[0].value = row.name;
      this.listData[1].value = row.releaseTime;
      this.listData[1].status = row.status;
      //console.log(this.listData);
    },
    //表格顶部右上侧按钮点击事件
    tableMenuBtnClick(type) {
      switch (type) {
        // 发布
        case "release":
          this.$request(
            "post",
            "/regularRelease/directRelease",
            {
              userId: "1",
              idList: this.multipleSelection,
            },
            (data) => {
              this.$message.success("发布成功!");
              this.getTableData();
            }
          );
          break;
        // 删除
        case "delete":
          this.$request(
            "post",
            "/regularRelease/deleteRelease",
            {
              userId: "1",
              idList: this.multipleSelection,
            },
            (data) => {
              this.$message.success("删除成功!");
              this.getTableData();
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建站点关键字":
          this.$request(
            "post",
            "/site/addKeyWord",
            {
              name: this.currentDialogData.listData[0].value,
              id: this.$route.query.id,
              content: this.currentDialogData.listData[1].value,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建站点关键字成功!");
              this.$request(
                "post",
                "/site/getKeyWordList",
                {
                  id: this.$route.query.id,
                },
                (data) => {
                  this.tableData = data.result;
                }
              );
            }
          );
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    filterType(value, row) {
      return row.type === value;
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      //console.log(`当前页: ${val}`);
      this.getTableData();
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      //console.log(`每页 ${val} 条`);
      this.getTableData();
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["taskInfo"],
          relationId: null,
          type: 0,
        },
        (data) => {
          if (
            data.result.taskInfo.checkList == null ||
            data.result.taskInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("taskInfo", JSON.stringify(data.result));
        }
      );
    },
  },
  beforeCreate() {
    localStorage.removeItem("taskInfo");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   taskInfo: {
    //     name: "任务列表",
    //     checkList: ["立即发布", "保存", "删除"],
    //   },
    // };
    // localStorage.setItem("taskInfo", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#taskInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .el-table {
      position: absolute;
      min-width: 1000px;
      overflow: auto;
      .tableBtnArea {
        display: flex;
      }
      .titleArea {
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        .newArea {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01cb94;
          position: absolute;
          float: left;
          left: -10px;
          top: 5px;
        }
        .picArea {
          width: 32px;
          height: 32px;
        }
        .videoArea {
          width: 32px;
          height: 32px;
        }
      }
      .operateArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > .btnArea {
          display: flex;
        }
        .itemBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.5s;
          margin: 0 3px;
          &:hover {
            color: #01cb94;
          }
        }
        .el-input {
          max-width: 200px;
        }
      }
      .el-table__cell {
        padding: 3px 0;
      }
    }
  }
  > .block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>