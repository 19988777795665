<template>
  <div id="roleAllocateAuthority">
    <el-dialog :title="currentDialogData.title" :visible.sync="dialogVisible" width="60%" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="itemOption" v-for="(item, index) in currentDialogData.listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :data="uploadData"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" :placeholder="'请输入' + item.name"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name" filterable>
            <el-option v-for="item2 in item.valueList" :key="item2.id" :label="item2.name" :value="item2.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="topArea">
      <div class="topLeft">
        <el-select @change="selectRole" v-model="value" filterable placeholder="请选择角色" value-key="id">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="topRight">
        <div class="roleSwitch">
          <el-switch v-model="isSetUp" @change="roleDisable = !roleDisable">
          </el-switch>
        </div>
        <div class="btnArea">
          <div @click="btnClickMethods(item, index)" class="itemBtn" v-for="(item, index) in btnData" :key="index">
            <div v-if="item.btnType == 'ordinary'">{{ item.label }}</div>
            <div v-if="item.btnType == 'checkBox'">
              <el-checkbox v-model="checkAll" :disabled="roleDisable">{{
                item.label
              }}
              </el-checkbox>
            </div>
            <div v-if="item.btnType == 'upload'">
              <el-upload class="avatar-uploader" :action="item.url" :show-file-list="false" :data="item.uploadData"
                :on-success="importSuccess" :on-error="importError" :before-upload="beforeAvatarUpload" accept=".json">
                {{ item.label }}
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentArea">
      <div class="contentLeft">
        <div class="checkAllArea">
          <div>角色名称：<input type="text" v-model="roleName" /></div>
          <el-checkbox v-model="currentTabCheckAll" @change="handleCheckAllChange" :disabled="roleDisable">本页全选
          </el-checkbox>
        </div>
        <template v-for="(item, index) in generalAuthorityData">
          <div class="itemArea" :key="'0' + index" v-if="true">
            <div class="item-a">
              <div class="itemTitleArea">{{ item.name }}:</div>
              <div class="itemContentArea">
                <div class="item-but" @click="checkAllAuthorityByModel(item, true)">
                  全选
                </div>
                <div class="item-but" @click="checkAllAuthorityByModel(item, false)">
                  取消
                </div>
              </div>
            </div>
            <div class="item-a">
              <div class="itemTitleArea"></div>
              <div class="itemContentArea">
                <el-checkbox-group v-model="item.checkList">
                  <el-checkbox v-for="(item2, index2) in item.authorityList" :key="index2" :label="item2"
                    :disabled="roleDisable"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </template>
        <template v-for="(item, index) in siteAuthorityData">
          <div class="itemArea" :key="'1' + index" v-if="flag == 1">
            <div class="item-a">
              <div class="itemTitleArea">{{ item.name }}:</div>
              <div class="itemContentArea">
                <div class="item-but" @click="checkAllAuthorityByModel(item, true)">
                  全选
                </div>
                <div class="item-but" @click="checkAllAuthorityByModel(item, false)">
                  取消
                </div>
              </div>
            </div>
            <div class="item-a">
              <div class="itemTitleArea"></div>
              <div class="itemContentArea">
                <el-checkbox-group v-model="item.checkList">
                  <el-checkbox v-for="(item2, index2) in item.authorityList" :key="index2" :label="item2"
                    :disabled="roleDisable"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </template>
        <template v-for="(item, index) in columnAuthorityData">
          <div class="itemArea" :key="'2' + index" v-if="flag > 1">
            <div class="item-a">
              <div class="itemTitleArea">{{ item.name }}:</div>
              <div class="itemContentArea">
                <div class="item-but" @click="checkAllAuthorityByModel(item, true)">
                  全选
                </div>
                <div class="item-but" @click="checkAllAuthorityByModel(item, false)">
                  取消
                </div>
              </div>
            </div>
            <div class="item-a">
              <div class="itemTitleArea"></div>
              <div class="itemContentArea">
                <el-checkbox-group v-model="item.checkList">
                  <el-checkbox v-for="(item2, index2) in item.authorityList" :key="index2" :label="item2"
                    :disabled="roleDisable"></el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="contentRight"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSetUp: true, // 当前站点或栏目的显示权限
      roleDisable: false, // 权限禁用
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      roleName: "",
      currentDialogData: {
        title: "新建角色",
        listData: [
          {
            type: "input",
            name: "角色名称",
            value: "",
          },
        ],
      },
      //角色列表
      options: [
        {
          id: "1",
          name: "管理人员",
        },
        {
          id: "2",
          name: "普通人员",
        },
        {
          id: "3",
          name: "审核发布人员",
        },
      ],
      btnData: [
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "新建",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "复制",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "删除",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "保存",
        },
        // {
        //   btnType: "checkBox",
        //   type: "authorityData",
        //   menu: "siteInfo",
        //   label: "所有全选",
        // },
      ],
      //选择的角色
      value: "",
      checkAll: false, //所有站点全选
      currentTabCheckAll: false, //本页全选
      flag: 0,
      // 通用权限数据
      generalAuthorityData: [
        {
          id: "menuList",
          name: "左侧菜单",
          checkList: [],

          authorityList: [
            "站点管理",
            "模板管理",
            "组件管理",
            "任务管理",
            "VR全景",
            "直播管理",
            "图片资源",
            "视频资源",
            "文件资源",
             "资源库",
            "协同管理",
            "角色管理",
            "权限管理",
            "运维审计",
            "人员管理",

          ],
        },
        {
          id: "siteManage",
          name: "站点管理",
          checkList: [],
          authorityList: ["查看", "新建站点", "新建栏目", "新建子栏目"],
        },
        {
          id: "templateInfo",
          name: "模板信息",
          checkList: [],
          authorityList: [
            "查看",
            "保存",
            "预览",
            "新建",
            "源码",
            "删除",
            // "导入",
            // "导出",
          ],
        },
        {
          id: "assemblyInfo",
          name: "组件信息",
          checkList: [],

          authorityList: [
            "查看",
            "保存",
            // "导入", "导出",
            "删除",
          ],
        },
        {
          id: "taskInfo",
          name: "任务列表",
          checkList: [],
          authorityList: ["查看", "立即发布", "保存", "删除"],
        },
        {
          id: "VRInfo",
          name: "VR全景",
          checkList: [],

          authorityList: ["查看", "新建", "保存", "预览", "删除"],
        },
        {
          id: "liveVideo",
          name: "直播管理",
          checkList: [],
          authorityList: ["查看", "新建", "预览", "保存", "删除"],
        },
        {
          id: "imageResource",
          name: "图片资源",
          checkList: [],

          authorityList: ["查看", "新建", "保存", "预览", "删除"],
        },
        {
          id: "videoResource",
          name: "视频资源",
          checkList: [],

          authorityList: ["查看", "新建", "保存", "预览", "删除"],
        },
        {
          id: "documentResource",
          name: "文件资源",
          checkList: [],

          authorityList: ["查看", "新建", "保存", "预览", "删除"],
        },
        // {
        //   id: "addressList",
        //   name: "通讯录",
        //   checkList: [],

        //   authorityList: ["保存", "删除"],
        // },
        // {
        //   id: "collection",
        //   name: "收藏夹",

        //   checkList: [],
        //   authorityList: ["保存", "删除"],
        // },
      ],
      // 站点权限数据
      siteAuthorityData: [
        {
          id: "siteKey",
          name: "站点关键字",
          checkList: [],
          authorityList: ["查看", "新建", "保存", "导入", "导出", "删除"],
        },
        {
          id: "siteInfo",
          name: "站点信息",
          checkList: [],
          authorityList: [
            "查看",
            "新建",
            "删除",
            "发布",
            "保存",
            "预览",
            "导入",
            "导出",
          ],
        },

        {
          id: "columnTrash",
          name: "栏目回收站",
          checkList: [],
          authorityList: ["查看", "预览", "恢复", "删除"],
        },
        {
          id: "docTrash",
          name: "文档回收站",
          checkList: [],
          authorityList: ["查看", "预览", "恢复", "删除"],
        },
        {
          id: "siteAdditionFile",
          name: "上传附件设置",
          checkList: [],
          authorityList: ["查看", "应用", "重置"],
        },
        {
          id: "IntelligentSiteBuilding",
          name: "智能建站",
          checkList: [],
          authorityList: ["查看", "建站"],
        },
        {
          id: "columnInfo",
          name: "栏目信息",
          checkList: [],
          authorityList: [
            "查看",
            "保存",
            "预览",
            "发布",
            "导入",
            "导出",
            "删除",
          ],
        },
        {
          id: "docInfo",
          name: "文档列表",
          checkList: [],
          authorityList: [
            "查看",
            "源码",
            "撰稿",
            "日志",
            "新建",
            "导入",
            "导出",
            "删除",
            "预览",
            "发布",
            "撤稿",
            "定时撤稿",
            "复制",
            "移动",
            "引用",
            "刷新",
            "调整顺序",
            "文档追踪",
            "置顶",
            "取消置顶",
          ],
        },
        {
          id: "dataStatistics",
          name: "数据统计",
          checkList: [],
          authorityList: [
            "导出",
          ],
        },
        {
          id: "docLog",
          name: "操作日志及文档版本",
          checkList: [],
          authorityList: ["查看日志", "查看", "预览", "回退", "删除"],
        },
        {
          id: "docUeditor",
          name: "撰稿",
          checkList: [],
          authorityList: [
            "查看",
            "保存",
            "提交",
            "驳回",
            "撤稿",
            "预览",
            "发布",
            "删除",
            "调整顺序",
            "置顶",
            "取消置顶",
            "保存并发布",
          ],
        },
      ],
      // 栏目权限数据
      columnAuthorityData: [
        {
          id: "columnInfo",
          name: "栏目信息",
          checkList: [],
          authorityList: [
            "查看",
            "保存",
            "预览",
            "发布",
            "导入",
            "导出",
            "删除",
          ],
        },

        {
          id: "docInfo",
          name: "文档列表",
          checkList: [],
          authorityList: [
            "查看",
            "源码",
            "撰稿",
            "日志",
            "新建",
            "导入",
            "导出",
            "删除",
            "预览",
            "发布",
            "撤稿",
            "定时撤稿",
            "复制",
            "移动",
            "引用",
            "刷新",
            "调整顺序",
            "文档追踪",
            "置顶",
            "取消置顶",
          ],
        },
        {
          id: "dataStatistics",
          name: "数据统计",
          checkList: [],
          authorityList: [
            "导出",
          ],
        },
        {
          id: "docLog",
          name: "操作日志及文档版本",
          checkList: [],
          authorityList: [
            "查看日志",
            "查看日志2",
            "查看",
            "预览",
            "回退",
            "删除",
          ],
        },
        {
          id: "docUeditor",
          name: "撰稿",
          checkList: [],
          authorityList: [
            "查看",
            "保存",
            "提交",
            "驳回",
            "撤稿",
            "预览",
            "发布",
            "删除",
            "调整顺序",
            "置顶",
            "取消置顶",
            "保存并发布",
          ],
        },
      ],
    };
  },
  methods: {
    //选择角色后
    selectRole(value) {
      this.roleName = value.name;
      this.clearAllStatus();
      this.$request(
        "post",
        "/authority/getAuthorityByRole",
        {
          userId: "1",
          roleId: this.value.id,
          type: this.flag,
          queryId: this.$route.query.id,
        },
        (data) => {
          if (data.result.authorityData.length > 0) {
            if (this.flag == 1) {
              for (
                let index = 0;
                index < data.result.authorityData.length;
                index++
              ) {

                for (let i = 0; i < data.result.authorityData[index].checkList.length; i++) {
                  this.siteAuthorityData[index].checkList.push(data.result.authorityData[index].checkList[i])
                }
                // this.siteAuthorityData[index].checkList =
                //   data.result.authorityData[index].checkList;
              }
              // this.siteAuthorityData = data.result.authorityData;
            } else {
              // this.columnAuthorityData = data.result.authorityData;
              for (
                let index = 0;
                index < data.result.authorityData.length;
                index++
              ) {
                // this.columnAuthorityData[index].checkList =
                //   data.result.authorityData[index].checkList;
                for (let i = 0; i < data.result.authorityData[index].checkList.length; i++) {
                  this.columnAuthorityData[index].checkList.push(data.result.authorityData[index].checkList[i])
                }
              }
            }
          } else {
            if (this.flag == 1) {
              for (
                let index = 0;
                index < this.siteAuthorityData.length;
                index++
              ) {
                this.siteAuthorityData[index].checkList = [];
              }
              // this.siteAuthorityData = data.result.authorityData;
            } else {
              // this.columnAuthorityData = data.result.authorityData;
              for (
                let index = 0;
                index < this.columnAuthorityData.length;
                index++
              ) {
                this.columnAuthorityData[index].checkList = [];
              }
            }
          }
          if (data.result.generalAuthorityData.length > 0) {
            // this.generalAuthorityData = data.result.generalAuthorityData;
            for (
              let index = 0;
              index < data.result.generalAuthorityData.length;
              index++
            ) {
              for (let i = 0; i < data.result.generalAuthorityData[index].checkList.length; i++) {
                this.generalAuthorityData[index].checkList.push(data.result.generalAuthorityData[index].checkList[i])
              }
            }
          } else {
            for (
              let index = 0;
              index < data.result.generalAuthorityData.length;
              index++
            ) {
              this.generalAuthorityData[index].checkList = [];
            }
          }
          this.isSetUp = data.result.isSetUp == "1" ? true : false;
          this.roleDisable = data.result.isSetUp == "1" ? false : true;
        }
      );
    },
    //右上按钮点击事件
    btnClickMethods(item, index) {
      switch (item.label) {
        case "新建":
          this.dialogVisible = true;
          this.currentDialogData.title = "新建角色";
          this.currentDialogData.listData = [
            {
              type: "input",
              name: "角色名称",
              value: "",
            },
          ];
          break;
        case "复制":
          this.dialogVisible = true;
          this.currentDialogData.title = "复制角色";
          this.currentDialogData.listData = [
            {
              type: "input",
              name: "角色名称",
              value: "",
            },
          ];
          break;
        case "删除":
          if (this.isRoleSelected()) {
            this.$confirm("确认删除该角色么?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/authority/deleteRole",
                  {
                    userId: "1",
                    roleId: this.value.id,
                  },
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.value = {};
                    this.clearAllSelect();
                    this.getRoleList();
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          }
          break;
        case "保存":
          if (this.isRoleSelected()) {
            this.$request(
              "post",
              "/authority/updateAuthorityData",
              {
                userId: "1",
                roleId: this.value.id,
                roleName: this.roleName,
                generalAuthorityData: this.generalAuthorityData,
                authorityData:
                  this.$route.query.flag == 1
                    ? this.siteAuthorityData
                    : this.columnAuthorityData,
                type: this.$route.query.flag,
                relationId: this.$route.query.id,
                isSetUp: this.isSetUp ? "1" : "0",
              },
              (data) => {
                this.$message({
                  type: "success",
                  message: "保存成功!",
                });
              }
            );
          }
          break;
        default:
          break;
      }
    },

    sureAddNew(title) {
      switch (title) {
        case "新建角色":
          let siteAuthorityData = JSON.parse(
            JSON.stringify(this.siteAuthorityData)
          );
          for (let index = 0; index < siteAuthorityData.length; index++) {
            const element = siteAuthorityData[index];
            element.checkList = [];
          }
          let columnAuthorityData = JSON.parse(
            JSON.stringify(this.columnAuthorityData)
          );
          for (let index = 0; index < columnAuthorityData.length; index++) {
            const element = columnAuthorityData[index];
            element.checkList = [];
          }
          let generalAuthorityData = JSON.parse(
            JSON.stringify(this.generalAuthorityData)
          );
          for (let index = 0; index < generalAuthorityData.length; index++) {
            const element = generalAuthorityData[index];
            element.checkList = [];
          }
          this.$request(
            "post",
            "/authority/addAuthority",
            {
              userId: "1",
              roleName: this.currentDialogData.listData[0].value,
              // generalAuthority: generalAuthorityData,
              // columnAuthority: columnAuthorityData,
              // siteAuthority: siteAuthorityData,
            },
            (data) => {
              this.$message.success("新建角色成功");
              this.getRoleList();
              this.dialogVisible = false;
            }
          );
          break;
        case "复制角色":
          if (this.isRoleSelected()) {
            this.$request(
              "post",
              "/authority/copyRole",
              {
                userId: "1",
                originRoleId: this.value.id,
                newName: this.currentDialogData.listData[0].value,
              },
              (data) => {
                this.$message.success("复制角色成功");
                this.getRoleList();
                this.dialogVisible = false;
              }
            );
          } else {
            return;
          }
          break;
        default:
          break;
      }
    },
    //判断是否已经选择角色
    isRoleSelected() {
      if (this.roleName == "") {
        this.$message.error("请先选择角色再进行操作");
        return false;
      }
      return true;
    },
    //获得角色列表
    getRoleList() {
      this.$request(
        "post",
        "/authority/getRoleList",
        {
          userId: "1",
        },
        (data) => {
          this.options = data.result;
        }
      );
    },

    //进行全选
    takeAllSelect() {
      for (let index = 0; index < this.generalAuthorityData.length; index++) {
        const element = this.generalAuthorityData[index];
        element.checkList = element.authorityList;
      }
      for (let index = 0; index < this.siteAuthorityData.length; index++) {
        const element = this.siteAuthorityData[index];
        element.checkList = element.authorityList;
      }
      for (let index = 0; index < this.columnAuthorityData.length; index++) {
        const element = this.columnAuthorityData[index];
        element.checkList = element.authorityList;
      }
    },
    //清除全选
    clearAllSelect() {
      for (let index = 0; index < this.generalAuthorityData.length; index++) {
        const element = this.generalAuthorityData[index];
        element.checkList = [];
      }
      for (let index = 0; index < this.siteAuthorityData.length; index++) {
        const element = this.siteAuthorityData[index];
        element.checkList = [];
      }
      for (let index = 0; index < this.columnAuthorityData.length; index++) {
        const element = this.columnAuthorityData[index];
        element.checkList = [];
      }
    },
    //全选切换
    handleCheckAllChange(value) {
      if (this.currentTabCheckAll) {
        this.takeAllSelect();
      } else {
        this.clearAllSelect();
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },

    /**
     *
     */
    importError(err, file, fileList) {
      this.$message({
        message: "导入失败!",
        type: "error",
      });
    },
    /**
     * 对当前模块的权限统一操作  flag为false 取消选择  flag为true 全选
     */
    checkAllAuthorityByModel(item, flag) {
      if (!this.roleDisable) {
        if (flag) {
          item.checkList = item.authorityList;
        } else {
          item.checkList = [];
        }
      }
    },
    /**
     * 清除当前所有check 用于每次选择角色之后的获取 防止上一次的push造成的角色权限数据的交叉
     */
    clearAllStatus() {
      this.checkAll = false;
      this.currentTabCheckAll = false;
      for (let i = 0; i < this.generalAuthorityData.length; i++) {
        this.generalAuthorityData[i].checkList = []
      }
      for (let i = 0; i < this.siteAuthorityData.length; i++) {
        this.siteAuthorityData[i].checkList = []
      }
      for (let i = 0; i < this.columnAuthorityData.length; i++) {
        this.columnAuthorityData[i].checkList = []
      }
    }
  },
  mounted() {
    this.isSetUp = true;
    this.roleDisable = false;
    this.flag = this.$route.query.flag;
    this.getRoleList();
  },
};
</script>

<style lang="less">
#roleAllocateAuthority {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  >.el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-dialog__body {
        height: auto;
        width: 100%;

        >.itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          >.leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          >.rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-select {
              width: 100%;
            }

            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }

      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  .topArea {
    width: 100%;
    height: auto;
    display: flex;

    .topLeft {
      flex: 1;
      height: auto;

      .el-input__inner {
        width: 300px;
        height: 30px;
      }

      .el-input__suffix {
        /* 控制箭头位置居中 */
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .topRight {
      flex: 1.5;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      >.btnArea {
        width: 100%;
        height: auto;
        padding: 0 0;
        // background-color: rgba(228, 228, 228, 0.301);
        display: flex;
        justify-content: space-around;
        align-items: center;
        justify-content: space-around;
        align-items: center;

        .itemBtn {
          width: 120px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #5d7bb5;
          border-radius: 10px;
          transition: 0.5s;

          &:hover {
            cursor: pointer;
            color: #ffffff;
            background-color: #5d7bb5;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }

  .contentArea {
    width: 100%;
    flex: 1;
    display: flex;
    overflow: hidden;
    border-top: 1px solid rgb(183, 183, 183);
    margin: 10px 0 0 0;

    >.contentLeft {
      flex: 1;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .checkAllArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .itemArea {
        display: flex;
        height: auto;
        width: 100%;
        overflow: auto;
        flex-shrink: 0;
        background-color: white;
        // border: 1px solid black;
        margin: 5px 0;
        flex-direction: column;

        .item-a {
          width: 100%;
          height: auto;
          display: flex;
          margin-bottom: 5px;
          padding-bottom: 5px;
          box-sizing: border-box;

          &:nth-child(1) {
            border-bottom: 1px solid #ececec;
          }

          .item-but {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #52a1fb;
            cursor: pointer;
            padding: 0 20px;
            font-size: 14px;

            &:hover {
              // text-decoration: underline;
              color: #0b54a7;
            }
          }
        }

        .itemTitleArea {
          display: flex;
          width: 150px;
          height: auto;
          justify-content: right;
          align-items: center;
          margin-right: 10px;
          flex-shrink: 0;
          margin-left: 10px;
          margin-top: 5px;
        }

        .itemContentArea {
          display: flex;
          flex: 1;
          height: auto;
          justify-content: left;
          align-items: center;
          // .el-checkbox-group {
          //   display: flex;
          // }
        }
      }
    }
  }
}
</style>
