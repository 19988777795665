<template>
  <div id="docUeditor_new">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea" v-if="index < 1">{{ item.name }}:</div>
        <!-- <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img  style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div> -->
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name">
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption" v-if="currentDialogData.listData[0].value == '2'">
        <div class="leftArea">{{ currentDialogData.listData[1].name }}:</div>
        <div class="rightArea">
          <el-date-picker
            v-model="currentDialogData.listData[1].value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="content">
      <div class="custom-format">
        <div class="title">
          <div class="tt">
            <div class="text">
              <span>*</span>
              标题:
            </div>
            <Editor
              style="
                width: 40%;
                height: 30px;
                overflow-y: hidden;
                border: 1px solid #eef0f2;
                border-radius: 7px;
                background-color: #f5f5f5;
                line-height: 25px;
                font-size: 13px;
              "
              v-model="listNewData[0].value"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
            <Toolbar
              :editor="editor1"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
          </div>
        </div>
        <div
          class="custom-but"
          v-for="(menu, index) in editorMenu"
          :key="index"
        >
          <div
            class="item"
            :id="menu.id"
            v-if="menu.type == 'input'"
            :onclick="menu.click"
          >
            <img :src="menu.icon" alt="" style="width: 15px; height: 15px" />
            {{ menu.lable }}
          </div>

          <div
            class="item"
            :id="menu.id"
            v-else-if="menu.type == 'select'"
            :onclick="menu.click"
          >
            <p
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ menu.lable }}
            </p>
            <i class="el-icon-caret-bottom"></i>
          </div>
          <div class="item" v-else @click="testCommand(menu.type)">
            <img :src="menu.icon" alt="" style="width: 15px; height: 15px" />
            {{ menu.lable }}
          </div>
        </div>
        <div v-if="!controlFlag" class="zk" @click="changeControl">展开>></div>
        <div v-if="controlFlag" class="zk" @click="changeControl">收起>></div>
      </div>
      <div id="editor" type="text/plain"></div>
    </div>
    <div :class="{ editerLeftArea: true, editerAreaActive: isLeftShow }">
      <div class="docTitle">文档属性配置</div>
      <div class="docAttrs">
        <div class="items" v-for="(item, index) in listNewData" :key="index">
          <div class="leftArea" v-if="index > 0">
            <span v-if="item.idNotNull">*</span>
            {{ item.name }}:
          </div>
          <div class="rightArea" v-if="item.type == 'pic'">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-image
                v-if="item.value != ''"
                :src="item.value"
                alt=""
                style="width: 100px; height: 50px"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div v-else class="upload-img">选择封面</div>
            </el-upload>
          </div>
          <div class="rightArea labelArea" v-if="item.type == 'label'">
            {{ item.value == "" ? "无" : item.value }}
          </div>
          <div class="rightArea" v-if="item.type == 'input'">
            <el-input
              v-model="item.value"
              placeholder="请输入内容"
              clearable
            ></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'textarea'">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.value"
              placeholder="请输入内容"
              clearable
            ></el-input>
          </div>
          <!-- <div class="rightArea" v-if="item.type == 'uediter'">
            <div class="title-uedit">
              <Editor
                style="
                  width: 100%;
                  height: 50px;
                  overflow-y: hidden;
                  border: none;
                  background-color: #f5f5f5;
                  line-height: 35px;
                "
                v-model="item.value"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
              <Toolbar
                :editor="editor1"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
            </div>
          </div> -->
          <div class="rightArea" v-if="item.type == 'dateTime'">
            <el-date-picker
              v-model="item.value"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              clearable
            >
            </el-date-picker>
          </div>
          <div class="rightArea" v-if="item.type == 'select'">
            <el-select v-model="item.value" placeholder="请选择">
              <el-option
                v-for="item2 in item.valueList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="rightArea" v-if="item.type == 'selectChange'">
            <el-select
              v-model="item.value"
              placeholder="请选择"
              @change="changeAddress"
            >
              <el-option
                v-for="item2 in item.valueList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="rightArea" v-if="item.type == 'time'">
            <el-date-picker
              v-model="item.value"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期"
              clearable
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="docButs">
        <template v-for="(item, index) in btnData2"
          ><div
            class="itemBtn"
            @click="btnClickMethods(item)"
            :key="index"
            v-has="item"
          >
            <i :class="item.icon"></i>
            {{ item.label }}
          </div></template
        >
      </div>
    </div>
    <div class="fixdBut" @click="isLeftShow = !isLeftShow">
      <i v-if="!isLeftShow" class="el-icon-d-arrow-right"></i>
      <i v-if="isLeftShow" class="el-icon-d-arrow-left"></i>
      <template v-for="(item, index) in btnData2">
        <div
          class="itemBtn"
          @click.stop="btnClickMethods(item)"
          v-has="item"
          :key="index"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.label"
            placement="right"
          >
            <!-- {{ item.label }} -->
            <div><i :class="item.icon" v-if="isLeftShow"></i></div>
          </el-tooltip>
        </div>
      </template>
    </div>

    <div id="virtualCloseBut" ref="virtualCloseBut"></div>
    <!-- <div class="contentArea">
      <div class="optionArea">
        <div class="itemOption" v-for="(item, index) in listData" :key="index">
          <div class="leftArea">{{ item.name }}:</div>
          <div class="rightArea" v-if="item.type == 'pic'">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                :src="item.value"
                alt=""
                style="width: 100px; height: 50px"
              />
            </el-upload>
          </div>
          <div class="rightArea labelArea" v-if="item.type == 'label'">
            {{ item.value }}
          </div>
          <div class="rightArea" v-if="item.type == 'input'">
            <el-input v-model="item.value" placeholder="请输入内容"></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'textarea'">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.value"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'uediter'">
            <div class="title-uedit">
              <Editor
                style="
                  width: 100%;
                  height: 50px;
                  overflow-y: hidden;
                  border: 1px solid #dcdfe6;
                  border-radius: 4px;
                  line-height: 50px;
                "
                v-model="item.value"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
              <Toolbar
                :editor="editor1"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
            </div>
          </div>
          <div class="rightArea" v-if="item.type == 'dateTime'">
            <el-date-picker
              v-model="item.value"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div class="rightArea" v-if="item.type == 'select'">
            <el-select v-model="item.value" placeholder="请选择">
              <el-option
                v-for="item2 in item.valueList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btnArea">
        <div
          class="itemBtn"
          @click="btnClickMethods(item)"
          v-for="(item, index) in btnData2"
          :key="index"
          v-has="item"
        >
          {{ item.label }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import hasDirective from "../../directives/has1.js";
import moment from "moment";
import u_title from "./components/title-uedit.vue";
export default {
  components: { Editor, Toolbar },
  directives: {
    has: hasDirective,
  },
  data() {
    return {
      isLeftShow: false,
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "mhwz",
        path: "",
        title: "文档logo",
      },
      controlFlag: true,
      editorMenu: [
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "格式清除",
          id: "edui16",
          click: "$EDITORUI['edui16']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/一键排版.png"),
          lable: "一键排版",
          id: "edui18",
          click: "$EDITORUI['edui18']._onArrowClick();",
        },
        // {
        //   type: "input",
        //   icon: require("../../assets/img/ueditor_icon/文本校对.png"),
        //   lable: "文本校对",
        //   id: "edui16",
        //   click: "$EDITORUI['edui16']._onClick();",
        // },
        // {
        //   type: "wordupload",
        //   icon: require("../../assets/img/ueditor_icon/导入word.png"),
        //   lable: "导入word",
        //   id: "edui281",
        //   click: "$EDITORUI['edui281']._onClick();",
        // },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居左对齐.png"),
          lable: "居左对齐",
          id: "edui121",
          click: "$EDITORUI['edui122']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居中对齐.png"),
          lable: "居中对齐",
          id: "edui122",
          click: "$EDITORUI['edui123']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居右对齐.png"),
          lable: "居右对齐",
          id: "edui123",
          click: "$EDITORUI['edui124']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居中.png"),
          lable: "居中",
          id: "edui16",
          click: "$EDITORUI['edui16']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/超链接.png"),
          lable: "超链接",
          id: "edui105",
          click: "$EDITORUI['edui135']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/附件.png"),
          lable: "附件",
          id: "edui105",
          click: "$EDITORUI['edui175']._onClick();",
        },
        {
          type: "imageupload",
          icon: require("../../assets/img/ueditor_icon/单图上传.png"),
          lable: "单图上传",
          id: "edui148",
          click: "$EDITORUI['edui148']._onClick();",
        },
        // {
        //   type: "input",
        //   icon: require("../../assets/img/ueditor_icon/多图上传.png"),
        //   lable: "多图上传",
        //   id: "edui153",
        //   click: "$EDITORUI['edui153']._onClick();",
        // },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/视频.png"),
          lable: "视频",
          id: "edui166",
          click: "$EDITORUI['edui166']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/格式刷.png"),
          lable: "格式刷",
          id: "edui17",
          click: "$EDITORUI['edui17']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/撤销.png"),
          lable: "撤销",
          id: "edui6",
          click: "$EDITORUI['edui6']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/重做.png"),
          lable: "重做",
          id: "edui7",
          click: "$EDITORUI['edui7']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/加粗.png"),
          lable: "加粗",
          id: "edui9",
          click: "$EDITORUI['edui9']._onClick();",
        },
        {
          type: "select",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "宋体",
          id: "edui92",
          click: "$EDITORUI['edui92']._onArrowClick();",
        },
        {
          type: "select",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "字号",
          id: "edui105",
          click: "$EDITORUI['edui105']._onArrowClick();",
        },
        // {
        //   type: "input",
        //   icon: require("../../assets/img/ueditor_icon/格式清除.png"),
        //   lable: "全文资源",
        //   id: "edui16",
        //   click: "$EDITORUI['edui16']._onClick();",
        // },
      ],
      listData: [
        {
          type: "input",
          name: "作者",
          value: "",
        },
        {
          type: "input",
          name: "信息来源",
          value: "",
        },
        {
          type: "pic",
          name: "封面",
          value: "",
        },
        {
          // type: "input",
          type: "uediter",
          name: "文档标题",
          value: "文档1",
        },
        {
          type: "label",
          name: "文档唯一标识",
          value: "文档1",
        },
        {
          type: "label",
          name: "文档发布地址",
          value: "https://www.baidu.com",
        },

        {
          type: "label",
          name: "文档状态",
          value: "发布",
        },
      ],
      listNewData: [
        {
          type: "uediter",
          name: "标题",
          value: "",
          key: "title",
          idNotNull: true,
        },
        {
          type: "selectChange",
          name: "类型",
          value: "",
          key: "type",
          valueList: [
            { name: "普通新闻", id: "1" },
            { name: "图片新闻", id: "2" },
            { name: "视频新闻", id: "3" },
            { name: "链接新闻", id: "5" },
          ],
        },
        { type: "input", name: "作者", value: "", key: "author" },
        { type: "input", name: "来源", value: "", key: "source" },
        // { type: "input", name: "关键字", value: "", key: "source" },
        { type: "pic", name: "封面", value: "", key: "pic" },
        {
          type: "select",
          name: "发布类型",
          value: "1",
          key: "timeType",
          valueList: [
            { name: "直接发布", id: "1" },
            { name: "定时发布", id: "2" },
          ],
        },
        { type: "time", name: "发布时间", value: "", key: "time" },
        { type: "label", name: "发布地址", value: "", key: "docUrl" },
      ],
      dialogVisible: false,
      currentDialogData: {
        title: "发布模式",
        listData: [
          {
            type: "select",
            name: "发布模式",
            value: "1",
            valueList: [
              {
                id: "1",
                name: "直接发布",
              },
              {
                id: "2",
                name: "定时发布",
              },
            ],
          },
          {
            type: "dateTime",
            name: "发布时间",
            value: "",
          },
        ],
      },
      msg: "",
      editor: {},
      imgList: [],
      addtionfileMenu: [
        {
          label: "附件名称",
        },
        {
          label: "附件大小",
        },
        {
          label: "上传人",
        },
        {
          label: "操作",
        },
      ],
      additionFile: [
        {
          id: "", //附件id
          name: "附件1", //附件名称
          fileSize: "2.1Mb", //附件大小
          uploadName: "当前用户", //上传人名称
          uploadId: "", //上传人id
        },
        {
          id: "", //附件id
          name: "附件2", //附件名称
          fileSize: "2.1Mb", //附件大小
          uploadName: "当前用户", //上传人名称
          uploadId: "", //上传人id
        },
      ],
      btnData2: [
        {
          type: "statusData",
          label: "保存",
          status: "",
          page: "docUeditor",
          icon: "el-icon-document-checked",
        },
        {
          type: "statusData",
          label: "提交",
          status: "",
          page: "docUeditor",
          icon: "el-icon-folder-checked",
        },
        {
          type: "statusData",
          label: "驳回",
          status: "",
          page: "docUeditor",
          icon: "el-icon-folder-delete",
        },
        {
          type: "statusData",
          label: "撤稿",
          status: "",
          page: "docUeditor",
          icon: "el-icon-document-delete",
        },
        {
          type: "statusData",
          label: "预览",
          status: "",
          page: "docUeditor",
          icon: "el-icon-view",
        },
        {
          type: "statusData",
          label: "发布",
          status: "",
          page: "docUeditor",
          icon: "el-icon-s-promotion",
        },
        // {
        //   type: "statusData",
        //   label: "导入",
        //   status: "",
        //   page: "docUeditor",
        //   icon:"el-icon-folder-checked"
        // },
        // {
        //   type: "statusData",
        //   label: "导出",
        //   status: "",
        //   page: "docUeditor",
        //   icon:"el-icon-folder-checked"
        // },
        // {
        //   type: "statusData",
        //   label: "删除",
        //   status: "",
        //   page: "docUeditor",
        // },
      ],
      editor1: null,
      html: "<p>hello</p>",
      toolbarConfig: {
        toolbarKeys: ["bold", "italic", "underline", "color", "fontFamily"],
      },
      editorConfig: {
        placeholder: "请输入标题...",
        scroll: false, // 是否滚动  默认true
        hoverbarKeys: [],
      },
      mode: "simple", // or 'simple'
      docId: "", // 当前文档的临时ID
    };
  },
  methods: {
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "image/png" || file.type == "image/jpeg") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传文件只能是png格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 2MB!");
      // }
      return isType;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      //console.log("上传成功!", response);
      let requestData = {};
      switch (response.title) {
        case "文档logo":
          for (let index = 0; index < this.listNewData.length; index++) {
            const element = this.listNewData[index];
            if (element.key == "pic") {
              element.value = response.result.path;
            }
          }
          // this.listData[2].value = response.result.path;
          break;
        default:
          break;
      }
    },
    changeControl() {
      this.controlFlag = !this.controlFlag;
      if (this.controlFlag == true) {
        this.toolBoxchange();
      } else {
        let toolBox = document.getElementsByClassName(
          "edui-editor-toolbarboxouter"
        )[0];
        toolBox.style.height = 0;
      }
    },
    //触发指令
    testCommand(type) {
      this.editor.execCommand(type);
    },
    //确认发布
    sureRelease() {
      let requestData = this.getAttsVlaue();
      this.$set(requestData, "id", this.docId);
      this.$set(requestData, "userId", "1");

      if (requestData.name == "") {
        this.$message.error("文档标题不能为空!");
        return;
      }
      if (requestData.name.length > 50) {
        this.$message.error("标题名称过长,请重新重新填写(最大长度50)!");
        return;
      }
      if (requestData.timeType == "") {
        this.$message.error("发布类型不能为空!");
        return;
      }
      if (requestData.timeType == "2" && requestData.time == "") {
        this.$message.error("定时发布时间不能为空!");
        return;
      }
      this.$request(
        "post",
        "/doc/updateManuscript",
        requestData,
        // {
        //   id: this.docId,
        //   userId: "1",
        //   title: this.listData[3].value,
        //   name: this.editor1.getText(),
        //   docOnly: this.listData[4].value,
        //   pic: this.listData[2].value,
        //   manuscript: "<content>" + this.getContent() + "</content>",
        //   contentTxt: this.getContentTxt(),
        //   author: this.listData[0].value,
        //   source: this.listData[1].value,
        // },
        (data) => {
          this.docId = data.result.docId;
          // if (requestData.timeType == "1") {
          // if (this.currentDialogData.listData[0].value == "1") {
          this.$request(
            "post",
            "/doc/release",
            {
              flag:false,
              docIdList: [this.docId],
              userId: "1",
            },
            (data) => {
              this.$message.success("发布成功！");
              // this.reload();
              // this.$router.go(0);
              setTimeout(() => {
                //console.log(this.$refs.virtualCloseBut);
                this.$refs.virtualCloseBut.click();
              }, 1000);
            }
          );
          // } else {
          //   this.$request(
          //     "post",
          //     "/regularRelease/releaseTiming",
          //     {
          //       id: this.docId,
          //       type: 3,
          //       releaseTime: requestData.time,
          //     },
          //     (data) => {
          //       this.$message.success("定时发布成功！");
          //       this.dialogVisible = false;
          //     }
          //   );
          // }
        }
      );
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //下方按钮点击事件
    btnClickMethods(item) {
      let requestData = {};
      switch (item.label) {
        case "保存":
          this.save();
          break;
        case "预览":
          this.$request(
            "post",
            "/doc/previewManuscript",
            {
              id: this.docId,
              manuscript: "<content>" + this.getContent() + "</content>",
              contentText: this.getContentTxt(),
            },
            (data) => {
              window.open(data.result.previewUrl, "_blank");
            }
          );
          break;
        case "撤稿":
          this.$request(
            "post",
            "/doc/unpublish",
            {
              id: this.docId,
              userId: "1",
            },
            (data) => {
              this.$message.success("撤稿成功!");
              setTimeout(() => {
                this.$router.go(0);
              }, 500);
            }
          );
          break;
        case "删除":
          this.$confirm("确认删除该么?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/doc/delete",
                {
                  userId: "1",
                  docIdList: [this.docId],
                  flag: false,
                },
                (data) => {
                  this.$message.success("删除成功！");
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "驳回":
          this.$request(
            "post",
            "/doc/rejectDoc",
            {
              id: this.docId,
              userId: "1",
            },
            (data) => {
              this.$message.success("驳回成功!");
            }
          );
          break;
        case "提交":
          requestData = this.getAttsVlaue();
          this.$set(requestData, "id", this.docId);
          this.$set(requestData, "userId", "1");

          if (requestData.name == "") {
            this.$message.error("文档标题不能为空!");
            return;
          }

          if (requestData.name.length > 50) {
            this.$message.error("标题名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          this.$request(
            "post",
            "/doc/updateManuscript",
            requestData,
            // {
            //   id: this.docId,
            //   userId: "1",
            //   title: this.listData[3].value,
            //   name: this.editor1.getText(),
            //   docOnly: this.listData[4].value,
            //   pic: this.listData[2].value,
            //   manuscript: "<content>" + this.getContent() + "</content>",
            //   contentTxt: this.getContentTxt(),
            //   author: this.listData[0].value,
            //   source: this.listData[1].value,
            // },
            (data) => {
              this.docId = data.result.docId;
              this.$request(
                "post",
                "/doc/submitDoc",
                {
                  id: this.docId,
                  userId: "1",
                },
                (data) => {
                  this.$message.success("提交成功!等待审核~");
                }
              );
            }
          );

          break;
        case "发布":
          // this.dialogVisible = true;
          this.sureRelease();
          break;

        default:
          break;
      }
    },
    save() {
      let requestData = this.getAttsVlaue();
      this.$set(requestData, "id", this.docId);
      this.$set(requestData, "userId", "1");
      if (requestData.name == "") {
        this.$message.error("文档标题不能为空!");
        return;
      }
      if (requestData.name.length > 50) {
        this.$message.error("标题名称过长,请重新重新填写(最大长度50)!");
        return;
      }
      if (requestData.type == "5" && requestData.docUrl == "") {
        this.$message.error("链接新闻的链接地址不可为空!");
        return;
      }
      this.$request(
        "post",
        "/doc/updateManuscript",
        requestData,
        // {
        //   id: this.docId,
        //   userId: "1",
        //   title: this.listData[3].value,
        //   name: this.editor1.getText(),
        //   docOnly: this.listData[4].value,
        //   pic: this.listData[2].value,
        //   manuscript: "<content>" + this.getContent() + "</content>",
        //   contentTxt: this.getContentTxt(),
        //   author: this.listData[0].value,
        //   source: this.listData[1].value,
        // },
        (data) => {
          this.$message.success("保存成功");
          this.docId = data.result.docId;
          this.getDocInfo();
          this.getManuscript();
        }
      );
    },
    test() {},
    ready(instance) {
      this.editor = instance; // 保存vue-ueditor-wrap实例

      this.editor.addListener(
        "simpleupload_customcomplete",
        function (types, id, link, title, alt) {
          //console.log(link);
          //等等等一堆代码
        }
      );
    },
    but() {
      //console.log(this.msg);
    },
    // 获取纯文本内容
    getContentTxt() {
      return this.editor.getContentTxt();
    },
    // 获取html源代码
    getContent() {
      return this.editor.getContent();
    },
    getManuscript() {
      //console.log("当前文档ID", this.docId);
      this.$request(
        "post",
        "/doc/getManuscript",
        {
          userId: "1",
          id: this.docId,
        },
        (data) => {
          this.editor.setContent("", false);
          this.editor.execCommand("insertHtml", data.result.manuscript);
        }
      );
    },
    toolBoxchange() {
      let toolBox = document.getElementsByClassName(
        "edui-editor-toolbarboxouter"
      )[0];
      toolBox.style.height = "auto";
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          userId: "1",
          key: ["docUeditor"],
          relationId: this.docId,
          columnId: this.$route.query.columnId,
          type: 3,
        },
        (data) => {
          if (
            data.result.docUeditor.checkList == null ||
            data.result.docUeditor.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("docUeditor", JSON.stringify(data.result));
        }
      );
    },
    onCreated(editor) {
      this.editor1 = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      // //console.log("当前编辑器所有菜单:",editor.getAllMenuKeys());
    },
    // 刷新界面或关闭当前标签页前,自动保存文档内容
    beforeunloadHandler(e) {
      if (this.getAttsVlaue().name == "") {
        return;
      }
      this.save();
    },
    getAttsVlaue() {
      let returnData = {};
      for (let index = 0; index < this.listNewData.length; index++) {
        const element = this.listNewData[index];
        this.$set(returnData, element.key, element.value);
      }
      this.$set(returnData, "name", this.editor1.getText());
      this.$set(returnData, "columnId", this.$route.query.columnId);
      this.$set(
        returnData,
        "manuscript",
        "<content>" + this.getContent() + "</content>"
      );
      this.$set(returnData, "contentTxt", this.getContentTxt());
      return returnData;
    },
    getDocInfo() {
      this.$request("post", "/doc/getDocInfo", { id: this.docId }, (data) => {
        this.listData[0].value = data.result.author;
        this.listData[1].value = data.result.source;
        this.listData[2].value = data.result.pic;
        this.listData[3].value = data.result.title;
        // this.listData[3].value = data.result.name;
        this.listData[4].value = data.result.docOnly;
        this.listData[5].value = data.result.docUrl;
        this.listData[6].value = data.result.status;

        for (let index = 0; index < this.listNewData.length; index++) {
          const element = this.listNewData[index];
          element.value = data.result[element.key];
          // 如果是链接新闻  发布地址为输入框
          if (data.result.source == "5") {
            if (element.key == "docUrl") {
              element.type = "input";
              element.name = "链接地址";
            }
          }
        }

        //改变按钮中的状态字段
        for (let index = 0; index < this.btnData2.length; index++) {
          const element = this.btnData2[index];
          element.status = data.result.status;
        }
      });
    },
    testClick() {
      //console.log("事件被触发");
    },
    // 选择新闻类型,改变发布地址的编辑状态
    changeAddress(el) {
      if (el == "5") {
        // 说明是链接新闻
        for (let index = 0; index < this.listNewData.length; index++) {
          const element = this.listNewData[index];
          if (element.key == "docUrl") {
            element.type = "input";
            element.name = "链接地址";
          }
        }
      } else {
        for (let index = 0; index < this.listNewData.length; index++) {
          const element = this.listNewData[index];
          if (element.key == "docUrl") {
            element.type = "label";
            element.name = "发布地址";
          }
        }
      }
    },
  },
  mounted() {
    // let that = this;
    this.docId = this.$route.query.id;
    window.UEDITOR_CONFIG.serverUrl =
      this.$baseurl + "/config?columnId=" + this.$route.query.columnId;
    //console.log(window.UEDITOR_CONFIG);
    this.editor = UE.getEditor("editor");
    //console.log(this.editor);
    this.editor.ready(() => {
      // 默认展开工具栏
      this.toolBoxchange();

      //单图上传回调
      this.editor.addListener("simpleUploadCall", (name, data) => {
        // this.imgList.push({
        //   title: data.title,
        //   url: this.editor.getOpt("imageUrlPrefix") + data.url,
        // });
        // //console.log(this.imgList);
        // //console.log(this.editor.getPlainTxt());
        // //console.log();
      });
      // 附件上传回调
      this.editor.addListener("allFileUpload", (name, data) => {
        // //console.log(data);
      });
      // word上传回调
      this.editor.addListener("wordupload", (name, data) => {
        //console.log("word上传", data);
      });
      // 字体字号选择事件的回调
      this.editor.addListener("ready", function (ue) {
        //edui93_content元素为动态生成，因此需要使用以下方法绑定事件
        $("#edui_fixedlayer").on(
          "click",
          "#edui93_content .edui-label.edui-listitem-label.edui-default",
          function () {
            $("#edui92").find("p").html($(this).html().trim("'")); //字体
          }
        );
        $("#edui_fixedlayer").on(
          "click",
          "#edui106_content .edui-label.edui-listitem-label.edui-default",
          function () {
            $("#edui105").find("p").html($(this).html().trim("'")); //字号
          }
        );
      });
      this.editor.addListener("selectionchange", function () {
        //字体、字号变化
        $("#edui92")
          .find("p")
          .html(this.queryCommandValue("fontfamily").split(",")[0]); //字体
        $("#edui105")
          .find("p")
          .html(this.queryCommandValue("fontsize").split(",")[0]); //字号
      });
      if (this.docId != "") {
        this.getManuscript();
      }
    });
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  beforeCreate() {
    localStorage.removeItem("docUeditor");
  },
  created() {
    this.docId = this.$route.query.id;

    if (this.docId != "" && this.docId != null) {
      this.getDocInfo();
    } else {
      //改变按钮中的状态字段
      for (let index = 0; index < this.btnData2.length; index++) {
        const element = this.btnData2[index];
        element.status = "未发布";
      }
    }
    //console.log("清空localStorage");
    this.getCurrentAuthorityData();
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },

  beforeDestroy() {
    const editor = this.editor1;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>

<style lang="less">
#docUeditor_new {
  width: 100%;
  height: 100%;
  display: flex;
  // flex-direction: column;
  overflow: hidden;
  position: relative;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select,
            .el-date-editor {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  .editerLeftArea {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    transition: 0.5s;
    opacity: 1;
    padding-left: 30px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    > .docTitle {
      width: 100%;
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1vw;
      font-weight: bolder;
      // border-bottom: 3px solid #f2f2f2;
    }
    > .docAttrs {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 8%;
      padding-right: 10px;
      box-sizing: border-box;

      > .items {
        width: 100%;
        height: auto;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        background-color: #f5f5f5;
        > .leftArea {
          width: 20%;
          height: 100%;
          padding-top: 1.5%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          font-size: 13px;
          font-weight: bolder;
          > span {
            color: #ff0000;
            font-size: 15px;
            width: 8px;
          }
        }
        > .rightArea {
          width: 0;
          flex: 1;
          height: auto;
          // max-width: 300px;
          margin: 5px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-select {
            width: 100%;
          }
          img {
            cursor: pointer;
          }
          .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 100%;
          }
          .el-input__inner {
            border: none;
            background-color: #f5f5f5;
            font-size: 11px;
          }
          .w-e-text-container .w-e-scroll,
          .w-e-toolbar,
          .w-e-bar-item :hover {
            background-color: #f5f5f5;
          }
          .w-e-bar-item .active {
            background-color: #ffe69f;
          }
          .w-e-text-placeholder {
            font-style: normal;
            color: #c0c4cc;
            font-size: 11px;
          }
          .image-slot {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: #dddddd;
          }
          .upload-img {
            width: 100px;
            height: 50px;
            border: 1px solid #dddddd;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #c0c4cc;
            font-size: 11px;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
              color: #62b9ff;
              text-decoration: underline;
            }
          }
        }
      }
    }
    > .docButs {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
      > .itemBtn {
        width: 80px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #5d7bb5;
        border-radius: 8px;
        transition: 0.5s;
        margin-left: 20px;
        margin-top: 20px;
        font-size: 15px;
        i {
          font-size: 11px;
          margin-right: 5px;
        }

        &:hover {
          cursor: pointer;
          color: #ffffff;
          background-color: #5d7bb5;
          border: 1px solid #ffffff;
        }
      }
    }
  }

  > .editerAreaActive {
    opacity: 0;
    flex: 0;
    transition: 0.5s;
  }
  .fixdBut {
    position: fixed;
    right: 0%;
    // top: 8.5%;
    width: 30px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    z-index: 9999;
    cursor: pointer;
    transition: 0.5s;
    > .el-icon-d-arrow-right::before {
      width: 16px;
      height: 16px;
    }
    > .itemBtn {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > i {
      // opacity: 0;
      transition: 0.5s;
      height: 30px;
      margin-top: 10px;
    }
    &:hover {
      // transform: rotate(-180deg);
      > i {
        color: #0042e0;
        // opacity: 1;
      }
    }
  }

  .content {
    width: 0;
    flex: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .custom-format {
      margin-top: 4px;
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      > .title {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;

        > .tt {
          width: 100%;
          height: 30px;
          display: flex;
          > .text {
            width: auto;
            padding: 0 10px;
            height: 100%;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: bolder;
            > span {
              width: auto;
              padding: 0 3px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: red;
              font-size: 15px;
              line-height: 30px;
            }
          }
        }
      }
      .custom-but {
        width: auto;
        height: 25px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 8px;
        box-sizing: border-box;
        border: 1px solid rgba(99, 99, 99, 0.301);
        border-radius: 8px;
        margin: 3px 4px;
        font-size: 11px;
        > .item {
          width: auto;
          height: auto;
          height: 20px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 3px 8px;
        }
        img {
          margin-right: 3px;
        }
        cursor: pointer;
      }
      .zk {
        position: absolute;
        right: 8px;
        bottom: 0;
        width: auto;
        height: 100%;
        font-size: 12px;
        // margin-right: 8px;
        color: cornflowerblue;
        transition: 0.3s;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    > #editor {
      flex: 3;
      width: 100%;
      overflow: auto;
      // padding: 0 0 25px 0;
      background-color: #f5f7f9;
      .edui-editor {
        width: 100% !important;
        height: 100%;
        // overflow: auto;
        background-color: #f5f7f9;
        display: flex;
        align-items: center;
        flex-direction: column;
        .edui-editor-iframeholder {
          height: 90% !important;
          // min-height: 90% !important;
          width: 62% !important;
          background-color: #ffffff !important;
          // overflow: hidden !important;
          padding: 2% 5%;
          box-sizing: border-box;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          margin-top: 0.8%;
        }
      }
    }
  }
  > .contentArea {
    // flex: 1;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    // overflow-y: auto;
    > .optionArea {
      height: auto;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      box-sizing: border-box;
      align-content: center;
      // background-color: rgba(228, 228, 228, 0.301);
      > .itemOption {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        > .leftArea {
          width: 180px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        > .rightArea {
          // width: 100px;
          flex: 1;
          height: 100%;
          max-width: 300px;
          margin: 5px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-select {
            width: 100%;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
    > .btnArea {
      padding: 10px;
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10%;
    }
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
  //工具条
  .edui-editor-toolbarboxouter {
    height: 0;
    overflow: hidden;
  }

  .title-uedit {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .w-e-text-placeholder {
    top: 3px !important;
  }
  .w-e-text-container p {
    margin: 3px 0;
  }

  .edui-default .edui-editor-breadcrumb {
    display: none !important;
  }

  #virtualCloseBut {
    width: 10px;
    height: 10px;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .w-e-select-list,
  .w-e-drop-panel {
    z-index: 1000 !important;
  }

  .w-e-bar-item {
    height: 30px;
  }
}
</style>