<template>
  <div id="assemblyInfo">
    <textarea id="codeArea" ref="textarea"></textarea>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btnArea">
      <div
        @click="btnClickMethods(item, index)"
        class="itemBtn"
        v-for="(item, index) in btnData"
        :key="index"
        v-has="item"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
// 引入全局实例
import _CodeMirror from "codemirror";
import { js_beautify, css_beautify, html_beautify } from "js-beautify";
// 核心样式
import "codemirror/lib/codemirror.css";
// 引入主题后还需要在 options 中指定主题才会生效
import "codemirror/theme/cobalt.css";

// 需要引入具体的语法高亮库才会有对应的语法高亮效果
// codemirror 官方其实支持通过 /addon/mode/loadmode.js 和 /mode/meta.js 来实现动态加载对应语法高亮库
// 但 vue 貌似没有无法在实例初始化后再动态加载对应 JS ，所以此处才把对应的 JS 提前引入
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/markdown/markdown.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/r/r.js";
import "codemirror/mode/shell/shell.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/mode/swift/swift.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon//lint/lint.css";
import "codemirror/addon/lint/lint.js";
// import style_html from "../utils/htmlformat.js";

// 尝试获取全局实例
const CodeMirror = window.CodeMirror || _CodeMirror;

export default {
  directives: {
    has: hasDirective,
  },
  name: "in-coder",
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      // 内部真实的内容
      code: "",
      // 默认的语法类型
      mode: "html",
      // 编辑器实例
      coder: null,
      // 默认配置
      options: {
        // 缩进格式
        tabSize: 2,
        // 主题，对应主题库 JS 需要提前引入
        // theme: "cobalt",
        // 显示行号
        lineNumbers: true,
        line: true,
        //高亮行数
        maxHighlightLength: 10000,
        //预加载行数
        viewportMargin: 10000,
        smartIndent: true, // 自动缩进
        autoCloseBrackets: true, // 自动补全括号

        matchBrackets: true, //括号匹配
        gutters: ["CodeMirror-lint-markers"],
        lint: true,
        //行高亮
        styleActiveLine: true,
      },
      // 支持切换的语法高亮类型，对应 JS 已经提前引入
      // 使用的是 MIME-TYPE ，不过作为前缀的 text/ 在后面指定时写死了
      modes: [
        {
          value: "css",
          label: "CSS",
        },
        {
          value: "javascript",
          label: "Javascript",
        },
        {
          value: "html",
          label: "XML/HTML",
        },
        {
          value: "x-java",
          label: "Java",
        },
        {
          value: "x-objectivec",
          label: "Objective-C",
        },
        {
          value: "x-python",
          label: "Python",
        },
        {
          value: "x-rsrc",
          label: "R",
        },
        {
          value: "x-sh",
          label: "Shell",
        },
        {
          value: "x-sql",
          label: "SQL",
        },
        {
          value: "x-swift",
          label: "Swift",
        },
        {
          value: "x-vue",
          label: "Vue",
        },
        {
          value: "markdown",
          label: "Markdown",
        },
      ],
      listData: [
        {
          type: "input",
          name: "模板名称",
          value: "",
        },
        {
          type: "input",
          name: "模板唯一标识",
          value: "",
        },
      ],
      btnData: [
        {
          type: "authorityData",
          page: "assemblyInfo",
          label: "保存",
        },
        {
          type: "authorityData",
          page: "assemblyInfo",
          label: "预览",
        },
        // {
        //   type: "authorityData",
        //   page: "assemblyInfo",
        //   label: "导入",
        // },
        // {
        //   type: "authorityData",
        //   page: "assemblyInfo",
        //   label: "导出",
        // },
        {
          type: "authorityData",
          page: "assemblyInfo",
          label: "删除",
        },
      ],
    };
  },
  mounted() {
    this._initialize();
    this.$request(
      "post",
      "/assembly/getAssemblyInfo",
      { id: this.$route.query.id },
      (data) => {
        this.listData[0].value = data.result.name;
        this.listData[1].value = data.result.assemblyOnly;
        this.coder.setValue(html_beautify(data.result.codeContent));
      }
    );
  },
  methods: {
    // 操作按钮点击事件
    btnClickMethods(item, index) {
      switch (item.label) {
        case "保存":
          if (this.listData[0].value == "") {
            this.$message.error("组件名称不能为空!");
            return;
          }
          if (this.listData[1].value == "") {
            this.$message.error("组件唯一标识不能为空!");
            return;
          }
          if (this.listData[0].value.length > 50) {
            this.$message.error("组件名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.listData[1].value.length > 100) {
            this.$message.error(
              "组件唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/assembly/updateAssembly",
            {
              id: this.$route.query.id,
              name: this.listData[0].value,
              assemblyOnly: this.listData[1].value,
              codeContent: this.code,
            },
            (data) => {
              this.$message.success("保存成功!");
              this.$emit("update", {
                type: "updateAssembly",
                id: this.$route.query.id,
              });
            }
          );
          break;
        case "删除":
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/assembly/deleteAssembly",
                {
                  id: this.$route.query.id,
                  type: this.$route.query.flag,
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.$emit("update", {
                    type: "deleteAssembly",
                    id: this.$route.query.id,
                  });
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "预览":
          this.$request(
            "post",
            "/assembly/preview",
            {
              id: this.$route.query.id,
              code: this.code,
            },
            (data) => {
              window.open(this.data.result.link, "_blank");
            }
          );

          break;

        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    test() {
      this.$request(
        "post",
        "/template/saveHtml",
        { code: this.code },
        (data) => {
          // //console.log(data.result.html);
          // style_html(data.result.html,2," ")
          this.$message.success("保存成功");
        }
      );
    },
    // 初始化
    _initialize() {
      // 初始化编辑器实例，传入需要被实例化的文本域对象和默认配置
      this.coder = CodeMirror.fromTextArea(this.$refs.textarea, this.options);
      // 编辑器赋值
      this.coder.setValue(this.value || this.code);

      // 支持双向绑定
      this.coder.on("change", (coder) => {
        this.code = coder.getValue();

        if (this.$emit) {
          this.$emit("input", this.code);
        }
      });
      this.coder.setOption("mode", `text/html`);

      // 尝试从父容器获取语法类型
      // if (this.language) {
      //   // 获取具体的语法类型对象
      //   let modeObj = this._getLanguage(this.language);

      //   // 判断父容器传入的语法是否被支持
      //   if (modeObj) {
      //     this.mode = modeObj.label;
      //   }
      // }
    },
    // 获取当前语法类型
    _getLanguage(language) {
      // 在支持的语法类型列表中寻找传入的语法类型
      return this.modes.find((mode) => {
        // 所有的值都忽略大小写，方便比较
        let currentLanguage = language.toLowerCase();
        let currentLabel = mode.label.toLowerCase();
        let currentValue = mode.value.toLowerCase();

        // 由于真实值可能不规范，例如 java 的真实值是 x-java ，所以讲 value 和 label 同时和传入语法进行比较
        return (
          currentLabel === currentLanguage || currentValue === currentLanguage
        );
      });
    },
    // 更改模式
    changeMode(val) {
      // 修改编辑器的语法配置
      //console.log(val);
      this.coder.setOption("mode", `text/${val}`);

      // 获取修改后的语法
      let label = this._getLanguage(val).label.toLowerCase();

      // 允许父容器通过以下函数监听当前的语法值
      this.$emit("language-change", label);
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["assemblyInfo"],
          relationId: null,
          type: "0",
        },
        (data) => {
          if (
            data.result.assemblyInfo.checkList == null ||
            data.result.assemblyInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("assemblyInfo", JSON.stringify(data.result));
        }
      );
    },
  },
  beforeCreate() {
    localStorage.removeItem("assemblyInfo");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   assemblyInfo: {
    //     name: "组件信息",
    //     checkList: ["保存", "导入", "导出", "删除"],
    //   },
    // };
    // localStorage.setItem("assemblyInfo", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#assemblyInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  #codeArea {
    overflow: auto;
  }
  .CodeMirror {
    flex: 1;
    // max-height: 500px;
    width: 100%;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
          width: 100px;
          height: 50px;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}

.CodeMirror-gutters,
.CodeMirror-linenumber {
  left: 0 !important;
}
.CodeMirror-gutter-wrapper {
  left: -30px !important;
}
</style>