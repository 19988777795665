import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from '../views/login.vue'
import siteManage from '../views/siteManage/siteManage.vue'
import siteInfo from '../views/siteManage/siteInfo.vue'
import siteKey from '../views/siteManage/siteKey.vue'
import siteCodeMirror from '../views/siteManage/siteCodeMirror.vue'
import siteStatistics from '../views/siteManage/siteStatistics.vue'
import siteTrash from '../views/siteManage/siteTrash.vue'
import siteAdditionFile from '../views/siteManage/siteAdditionFile.vue'
import IntelligentSiteBuilding from '../views/siteManage/IntelligentSiteBuilding.vue'
import columnInfo from '../views/column/columnInfo.vue'
import columnTrash from '../views/siteManage/columnTrash.vue'
import docTrash from '../views/siteManage/docTrash.vue'
import columnCodeMirror from '../views/column/columnCodeMirror.vue'
import docInfo from '../views/doc/docInfo.vue'
import docDataStatistics from '../views/doc/docDataStatistics.vue'


import docUeditor from '../views/doc/docUeditor.vue'
import docUeditor2 from '../views/doc/docUeditor2.vue'
import docUeditor_new from '../views/doc/docUeditor_new.vue'
import docUeditor_new_V2 from '../views/doc/docUeditor_new_v2.vue'


import docCodeMirror from '../views/doc/docCodeMirror.vue'
import test from '../views/test.vue'
import errorPage from '../views/errorPage.vue'
import test1 from '../views/test1.vue'
import PersonneManagement from '../views/PersonneManagement.vue'
import templateManage from '../views/template/templateManage.vue'
import templateInfo from '../views/template/templateInfo.vue'
import templateCodeMirror from '../views/template/templateCodeMirror.vue'
import assemblyManage from '../views/assembly/assemblyManage.vue'
import assemblyInfo from '../views/assembly/assemblyInfo.vue'
import taskManage from '../views/task/taskManage.vue'
import taskInfo from '../views/task/taskInfo.vue'
import VRInfo from '../views/VR/VRInfo.vue'
import liveVideo from '../views/liveVideo/liveVideo.vue'
import imageResource from '../views/imageResource/imageResource.vue'
import videoResource from '../views/videoResource/videoResource.vue'
import documentResource from '../views/documentResource/documentResource.vue'
import resourceK from '../views/resourceK/resourceK.vue'
import coordinationManage from '../views/coordination/coordinationManage.vue'
import addressList from '../views/coordination/addressList.vue'
import collection from '../views/coordination/collection.vue'
// import authorityManage from '../views/authority/authorityManage.vue'
import authorityAllocate from '../views/authority/authorityAllocate.vue'
import roleManage from '../views/role/roleManage.vue'
import roleAllocateAuthority from '../views/role/roleAllocateAuthority.vue'
import audit from '../views/audit/audit.vue'
import Loadding from '../views/loadding.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '登录',
    component: login,
},
{
    path: '/',
    name: '/首页',
    component: index,
    children: [{
        path: "index",
        name: "/首页",
        component: Loadding
    }, {
        path: 'siteManage',
        name: '/首页/站点管理',
        component: siteManage,
        children: [{
            path: 'siteInfo',
            name: '/首页/站点管理/站点信息',
            component: siteInfo
        },
        {
            path: 'siteKey',
            name: '/首页/站点管理/站点关键字',
            component: siteKey
        },
        {
            path: 'siteStatistics',
            name: '/首页/站点管理/站点关键字',
            component: siteStatistics
        }, {
            path: 'siteTrash',
            name: '/首页/站点管理/站点回收站',
            component: siteTrash
        }, {
            path: 'siteAdditionFile',
            name: '/首页/站点管理/传附件设置',
            component: siteAdditionFile
        }, {
            path: 'IntelligentSiteBuilding',
            name: '/首页/站点管理/智能建站',
            component: IntelligentSiteBuilding
        },
        {
            path: 'columnInfo',
            name: '/首页/站点管理/栏目管理',
            component: columnInfo
        }, {
            path: 'columnTrash',
            name: '/首页/站点管理/栏目回收站',
            component: columnTrash
        }, {
            path: 'docTrash',
            name: '/首页/站点管理/文档回收站',
            component: docTrash
        },
        {
            path: 'docInfo',
            name: '/首页/站点管理/文档信息',
            component: docInfo
        },
        {
            path: 'dataStatistics',
            name: '/首页/站点管理/数据统计',
            component: docDataStatistics
        },
        ]
    },
    {
        path: 'roleManage',
        name: '/首页/角色管理',
        component: roleManage,
        children: [{
            path: 'roleAllocateAuthority',
            name: '/首页/角色信息',
            component: roleAllocateAuthority
        },]
    },
    {
        path: 'templateManage',
        name: '/首页/模板管理',
        component: templateManage,
        children: [{
            path: 'templateInfo',
            name: '/首页/模板信息',
            component: templateInfo
        },

        ]
    }, {
        path: 'assemblyManage',
        name: '/首页/组件管理',
        component: assemblyManage,
        children: [{
            path: 'assemblyInfo',
            name: '/首页/组件信息',
            component: assemblyInfo
        },]
    }, {
        path: 'taskManage',
        name: '/首页/任务管理',
        component: taskManage,
        children: [{
            path: 'taskInfo',
            name: '/首页/组件信息',
            component: taskInfo
        },]
    }, {
        path: 'VRInfo',
        name: '/首页/VR管理',
        component: VRInfo,
    }, {
        path: 'liveVideo',
        name: '/首页/直播管理',
        component: liveVideo,
    }, {
        path: 'imageResource',
        name: '/首页/图片资源',
        component: imageResource,
    },
    {
        path: 'videoResource',
        name: '/首页/视频资源',
        component: videoResource,
    },
    {
        path: 'documentResource',
        name: '/首页/文件资源',
        component: documentResource,
    },
    {
        path: 'resourceK',
        name: '/首页/资源库',
        component: resourceK,
    },
    {
        path: 'coordinationManage',
        name: '/首页/协同管理',
        component: coordinationManage,
        children: [{
            path: 'addressList',
            name: '/首页/协同管理/通讯录',
            component: addressList
        }, {
            path: 'collection',
            name: '/首页/协同管理/收藏夹',
            component: collection
        },]
    }, {
        path: 'authorityAllocate',
        name: '/首页/权限管理',
        component: authorityAllocate,
        // children: [ {
        //     path: 'authorityAllocate',
        //     name: 'authorityAllocate',
        //     component: authorityAllocate
        // }, ]
    },

    {
        path: 'audit',
        name: '/首页/运维审计',
        component: audit,
    },
    {
        path: 'PersonneManagement',
        name: '/首页/人员管理',
        component: PersonneManagement,
    }
    ]
},
{
    path: '/test',
    name: '测试',
    component: test,
}, {
    path: '/test1',
    name: '测试',
    component: test1,
},

{
    path: '/docUeditor',
    name: '/文档编辑器',
    component: docUeditor_new_V2
    // name: 'docUeditor',
    // component: docUeditor
}, {
    path: '/docUeditor2',
    name: 'docUeditor2',
    component: docUeditor2
}, {
    path: '/siteCodeMirror',
    name: '/站点编辑器',
    component: siteCodeMirror
}, {
    path: '/columnCodeMirror',
    name: '/栏目编辑器',
    component: columnCodeMirror
}, {
    path: '/docCodeMirror',
    name: '/文档编辑器',
    component: docCodeMirror
},
{
    path: '/templateCodeMirror',
    name: '/模板编辑器',
    component: templateCodeMirror
}, {
    path: '/errPage',
    name: '错误页面',
    component: errorPage,
},
]

const router = new VueRouter({
    routes
})

//重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//导航守卫
router.beforeEach((to, from, next) => {
    // if (localStorage.getItem('userId') == null && to.name != 'login') {
    //     Vue.prototype.$message.error("未登录！或登录失效！请重新登陆！");
    //     window.open("http://27.50.21.55:17001/isc_sso/login", "_self");
    // }
    if (to.name != "登录" && to.name != "测试") {
        let resourceId = ""
        let path = to.path.split("/")
        for (let index = 0; index < path.length; index++) {
            const element = path[index];
            if (element != "") {
                resourceId = resourceId == "" ? element : resourceId + "_" + element
            }
        }
        let requestData = {
            userId: localStorage.getItem('userId'),
            name: localStorage.getItem('name'),
            loginName: localStorage.getItem("userName"),
            resourceId: resourceId,
            resourceName: to.name,
            url: to.path
        }
        Vue.prototype.$request("post", "/joinAccess", requestData, (data) => {

        })
    }
    next()
})
export default router
