<template>
    <div id="resourceK">

    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
#resourceK {
    width: 100%;
    height: 100%;
}
</style>
